import type { CAAttachments, SDSAttachments } from './types';

export const CASEARCH_REQUESTED = 'CASEARCH_REQUESTED' as const;
export const caSearchRequested = (batchNo: string) => ({
  type: CASEARCH_REQUESTED,
  payload: { batchNo }
});

export const SDSSEARCH_REQUESTED = 'SDSSEARCH_REQUESTED' as const;
export const sdsSearchRequested = (itemNo: string) => ({
  type: SDSSEARCH_REQUESTED,
  payload: { itemNo }
});

export const CASEARCH_RECEIVED = 'CASEARCH_RECEIVED' as const;
export const receiveCASearch = (cAAttachmentName: CAAttachments[]) => ({
  type: CASEARCH_RECEIVED,
  payload: { cAAttachmentName },
});

export const SDSSEARCH_RECEIVED = 'SDSSEARCH_RECEIVED' as const;
export const receiveSDSSearch = (sdsAttachmentName: SDSAttachments[]) => ({
  type: SDSSEARCH_RECEIVED,
  payload: { sdsAttachmentName },
});

export const CADOWNLOAD_REQUESTED = 'CADOWNLOAD_REQUESTED' as const;
export const caDownloadRequested = (fileName: string, batchNo: string, itemNo: string) => ({
  type: CADOWNLOAD_REQUESTED,
  payload: { fileName, batchNo, itemNo }
});

export const CADOWNLOAD_RECEIVED = 'CADOWNLOAD_RECEIVED' as const;
export const caDownloadReceived = (fileData: string) => ({
  type: CADOWNLOAD_RECEIVED,
  payload: { fileData }
});

export const CADOWNLOAD_CLEAR = 'CADOWNLOAD_CLEAR' as const;
export const caDownloadClear = () => ({
  type: CADOWNLOAD_CLEAR,
});

export type CASearchRequestedAction = ReturnType<
  | typeof caSearchRequested
>;

export type SDSSearchRequestedAction = ReturnType<
  | typeof sdsSearchRequested
>;

export type ReceivedAction = ReturnType<
  | typeof receiveCASearch
  | typeof caDownloadReceived
  | typeof receiveSDSSearch
  | typeof caDownloadClear
>;

export type CASearchDownloadRequestedAction = ReturnType<
  | typeof caDownloadRequested
>;