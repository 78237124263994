import { Product, Suggestion } from './types';

export const PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED = 'PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED' as const;
export const searchProducts = (keywords: string, count: number, ignoreGrouping = false, isQuoteCart: boolean) => ({
  type: PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED,
  payload: { keywords, count, ignoreGrouping, isQuoteCart },
});


export const PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED = 'PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED' as const;
export const receiveSearchSuggestions = (suggestions: Suggestion[]) => ({
  type: PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED,
  payload: { suggestions },
});

export const PRODUCT_SELECTOR_PRODUCT_REQUESTED = 'PRODUCT_SELECTOR_PRODUCT_REQUESTED' as const;
export const requestProduct = (id: string, ignoreOrderable: boolean, ignoreGrouping = false) => ({
  type: PRODUCT_SELECTOR_PRODUCT_REQUESTED,
  payload: { id, ignoreOrderable, ignoreGrouping },
});

export const PRODUCT_SELECTOR_PRODUCT_RECEIVED = 'PRODUCT_SELECTOR_PRODUCT_RECEIVED' as const;
export const receiveProduct = (product: Product) => ({
  type: PRODUCT_SELECTOR_PRODUCT_RECEIVED,
  payload: { product },
});

export const PRODUCT_SELECTOR_PRODUCT_CLEARED = 'PRODUCT_SELECTOR_PRODUCT_CLEARED' as const;
export const clearProduct = () => ({
  type: PRODUCT_SELECTOR_PRODUCT_CLEARED,
});

export type ReceiveSearchSuggestionsAction = ReturnType<typeof receiveSearchSuggestions>;
export type ReceiveProductAction = ReturnType<typeof receiveProduct>;

export type ProductSelectorAction = ReturnType<
  | typeof searchProducts
  | typeof requestProduct
  | typeof clearProduct
>
  | ReceiveSearchSuggestionsAction
  | ReceiveProductAction;
