export const enum FormName {
  Registration = 'registration',
  SubAccount = 'subAccount',
  ChangePassword = 'changePassword',
  ContactUs = 'contactUs',
  Review = 'review',
  GroupPageReview = 'groupPageReview', //Ticket 171069: Cambridge Isotope 3.3. Redesign – product group page
  ForgotPassword = 'forgotPassword',
  SaveOrderTemplate = 'saveOrderTemplate',
  CreateProspect = 'createProspect',
  EditProfile = 'editProfile',
  CreateDocFreeReturnOrder = 'createDocFreeReturnOrder',
  CreateDocBasedReturnOrder = 'createDocBasedReturnOrder',
  NewsletterSubscribe = 'newsletterSubscribe',
  NewsletterUnsubscribe = 'newsletterUnsubscribe',
  ResetPassword = 'resetPassword',
  Comment = 'comment',
  CreateOrderTemplate = 'CreateOrderTemplate', //Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
  EditOrderTemplate = 'EditOrderTemplate', //Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
}
