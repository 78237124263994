import styles from './Search.module.scss';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useOnChange } from 'utils/hooks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autosuggest, highlightStringBySpans } from 'components/primitives/autosuggest';
import { escapeRegexCharacters } from 'utils/helpers';
import { debounce } from 'lodash';
import { useHeaderContext } from 'components/sections/headerContext';
import SuggestionItem from './SuggestionItem';
import InsertSymbolBlock from './InsertSymbolBlock';
import { SearchContainerContext } from './SearchContainerContext';
import SuggestionsContainer from './SuggestionsContainer';
import { searchProducts, searchProductsClear } from 'behavior/products/search/suggestions';
import { navigateTo } from 'behavior/events';
import { RouteName } from 'routes';
import { defaultSearchParams } from './constants';
import { trackProductClick, EventSource } from 'behavior/analytics';
import SearchButton from './SearchButton';
import { getThemeFontSizeClassName } from 'components/theme';
import { SimpleText } from 'components/sanaText';

const defaultDependencies = [];

const SearchBox = ({
  placeholder,
  searchText,
  searchTitle,
  searchProducts,
  searchProductsClear,
  suggestions,
  navigateTo,
  trackProductClick,
  routePath,
  noImageUrl,
  viewMode,
  routeData,
  prevRouteData,
  className = '',
  onFocus,
  onBlur,
  inputId,
  disabled,
  options,
  style,
  ariaLabel,
}) => {
  const headerContext = useHeaderContext();
  const formRef = useRef(null);
  const highlightedSuggestionRef = useRef();
  const [maxHeight, setMaxHeight] = useState('');
  const [value, setValue] = useState(headerContext.searchInputValue || '');
  const [showInfo, setShowInfo] = useState(true);
  const [showSymbolBar, setShowSymbolBar] = useState(false);
  const timeoutIdRef = useRef();
  let isContentAvail = false; /*2.4 Search � Content search results - #183781*/

  if (headerContext.available)
    headerContext.searchInputValue = value;

  useOnChange(() => {
    if (!headerContext.available) {
      setValue('');
      return;
    }

    const routeName = routeData?.routeName;
    if (routeName === RouteName.Search && routeData.params.q) {
      setValue(routeData.params.q);
      return;
    }

    if (!value)
      return;

    const prevRouteName = prevRouteData?.routeName;

    if (
      prevRouteName
      && prevRouteName === routeName
      && prevRouteData.params.language !== routeData.params.language
    )
      return;

    if (
      prevRouteName === RouteName.Search
      && routeName === RouteName.ProductDetails
    )
      return;

    setValue('');
  }, [routeData, prevRouteData], false);

  useEffect(() => {
    const resizeHandler = debounce(() => {
      if (!formRef.current)
        return;

      setMaxHeight(calculateMaxHeight(formRef.current));
    }, 250);

    isContentAvail = !!window ? window.location.search.includes('isContent=1') : false; /*2.4 Search � Content search results - #183781*/
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [formRef.current]);

  useEffect(() => () => clearTimeout(timeoutIdRef.current), []);

  //Ticket 183782: Cambridge Isotope : Phase 2- 2.5. Search – Special characters
  const onChangeSymbol = useCallback(event => {
    setValue(headerContext.searchInputValue + event.target.value);
    const value = headerContext.searchInputValue + event.target.value;
    search(value);
    document.getElementById(inputId).focus();
  }, []);

  const extendedOnFocus = useCallback(e => {
    setShowInfo(true);
    setShowSymbolBar(true);
    onFocus && onFocus(e);
  }, []);

  const search = useMemo(
    () => debounce(value => !disabled && searchProducts(value), 250),
    [disabled],
  );

  const onFetchRequested = useCallback(({ value, reason }) => {
    setMaxHeight(calculateMaxHeight(formRef.current));
    if (reason === 'input-changed' && value.length > 1)
      search(value);
  }, defaultDependencies);

  const onSelected = useCallback((event, { suggestion }) => {
    /*2.4 Search � Content search results - #183781*/
    if (suggestion.id === 'show_more_products' || suggestion.id === 'show_more_elements') {
      return;
    }
    event.preventDefault();
    searchProductsClear();
    setValue('');
    document.getElementById('layout').focus();
    trackProductClick({ product:suggestion, source: EventSource.SearchInput });

    /*2.4 Search � Content search results - #183781*/
    const url = document.URL;
    const tempArray = url.split('?');
    const baseURL = tempArray[0];
    const baseURLArr = baseURL.split('/');

    if (suggestion.isContent === 'True') {
      
      let contentUrl = suggestion.contentUrl;

      contentUrl = '/' + contentUrl;

      navigateTo(suggestion.routeName, contentUrl);
    }
    else {
      navigateTo(suggestion.routeData, suggestion.url);
    }

    // setTimeout to prevent combobox announcing about aria-activedescendant change after navigation.
    timeoutIdRef.current = setTimeout(() => {
      setShowInfo(false);
      /*2.4 Search � Content search results - #183781*/
      if (suggestion.isContent === 'True') {
        let contentUrl = suggestion.contentUrl;
        contentUrl = '/' + contentUrl;

        navigateTo(suggestion.routeName, contentUrl);
      }
      else {
        if (suggestion.productGroup) {
          navigateTo(suggestion.productGroup.routeData, suggestion.productGroup.url);
          //navigateTo(suggestion.productGroup.routeData, (suggestion.isContent === 'True') ? suggestion.id : suggestion.id === 'product search results' ? suggestion.contentUrl : suggestion.productGroup.url);
          return;
        }

        trackProductClick({
          product: suggestion,
          source: EventSource.SearchInput,
        });

        navigateTo(suggestion.routeData, suggestion.url);
      //navigateTo(suggestion.routeData, (suggestion.isContent === 'True') ? suggestion.id : suggestion.id === 'product search results' ? suggestion.contentUrl : suggestion.url);
      }
      
    }, 0);
  }, defaultDependencies);

  const onSubmit = useCallback(event => {
    if (!isContentAvail) {
      event.preventDefault();
      if (disabled)
        return;
      searchProductsClear();
      const encodedValue = encodeURIComponent(value);
      const searchURL = routePath.replace(defaultSearchParams, encodedValue);

      navigateTo({ routeName: RouteName.Search, params: { q: value, viewMode } }, searchURL);

      document.getElementById('layout').focus();
    }
  
  }, [routePath, value, disabled]);

  const onHighlighted = ({ suggestion }) => {
    highlightedSuggestionRef.current = suggestion;
  };

  /*2.4 Search � Content search results - #183781*/
  const renderSectionTitle = section => (<strong>{section.title}</strong>);

  /*2.4 Search � Content search results - #183781*/
  function getSectionSuggestions(section) {
    return section.languages;
  }

  const renderItem = useCallback(suggestion => (
    <SuggestionItem suggestion={suggestion} options={options} />
  ), defaultDependencies);
  const onChange = useCallback((_event, { newValue }) => setValue(newValue), defaultDependencies);

  const onKeyDown = e => {
    if (highlightedSuggestionRef.current && e.key === 'Tab') {
      onSelected(e, { suggestion: highlightedSuggestionRef.current });
      e.preventDefault();
    }
  };

  const inputProps = {
    id: inputId,
    placeholder,
    value,
    onChange,
    onFocus: extendedOnFocus,
    onBlur,
    onKeyDown,
    name: 'q',
  };

  const memorizedSuggestions = useMemo(() => getSuggestions(value, suggestions, noImageUrl), [suggestions]);
  const searchPathWithoutParams = useMemo(() => routePath ? routePath.split('?')[0] : '', [routePath]);

  /*2.4 Search � Content search results - #183781*/
  const productList = memorizedSuggestions.filter(form => (form.isContent !== 'True')).slice(0, 5);
  const showMoreProductItem = memorizedSuggestions.filter(form => (form.isContent === 'True' && form.id === 'show_more_products'));

  /*2.4 Search � Content search results - #183781*/
  if (showMoreProductItem.length > 0)
    productList.push(showMoreProductItem[0]);

  const contentList = memorizedSuggestions.filter(form => form.isContent === 'True' && form.id !== 'show_more_products' && form.id !== 'show_more_elements').slice(0, 5);
  const showMoreContentItem = memorizedSuggestions.filter(form => form.isContent === 'True' && form.id === 'show_more_elements');

  if (showMoreContentItem.length > 0)
    contentList.push(showMoreContentItem[0]);

  const memorizedSuggestionsProduct =
  {
    title: 'Product',
    languages: productList,
  };

  const memorizedSuggestionsContent =
  {
    title: 'Content',
    languages: contentList,
  };

  const memorizedSuggestionsTest = [];

  if (productList.length > 0) {
    memorizedSuggestionsTest.push(memorizedSuggestionsProduct);
  }
  if (contentList.length > 0) {
    memorizedSuggestionsTest.push(memorizedSuggestionsContent);
  }

  return (
    <div
      style={style}
      className={`${styles.searchBox} ${className} ${getThemeFontSizeClassName(options.boxThemeFontSize)}`}
    >
      <form role="search" aria-label={ariaLabel} method="get" action={searchPathWithoutParams} onSubmit={onSubmit} ref={formRef}>
        <SearchContainerContext.Provider value={{ maxHeight }}>
          <label className="visually-hidden" htmlFor={inputId}><SimpleText textKey="Search" /></label>
          <Autosuggest
            suggestions={memorizedSuggestionsTest}
            onFetchRequested={onFetchRequested}
            onClearRequested={searchProductsClear}
            onSelected={onSelected}
            onHighlighted={onHighlighted}
            getItemValue={getItemValue}
            renderItem={renderItem}
            renderItemsContainer={SuggestionsContainer}
            renderSectionTitle={renderSectionTitle}
            getSectionSuggestions={getSectionSuggestions}
            multiSection
            inputProps={inputProps}
            theme={styles}
            id={inputId} // Suffix to be added to 'react-autowhatever-' to resolve ID duplicates conflict.
            showInfo={showInfo}
          />
        </SearchContainerContext.Provider>
        <SearchButton text={searchText} title={searchTitle} options={options} />
        {/*Ticket 183782: Cambridge Isotope : Phase 2- 2.5. Search – Special characters*/}
        {showSymbolBar &&
          <InsertSymbolBlock onChangeSymbol={onChangeSymbol} showSymbolBar={showSymbolBar} onClickOutside={() => { setShowSymbolBar(false);}} formRef={formRef} />
        }
        
      </form>
    </div>
  );

};

const routeDataPropType = PropTypes.shape({
  routeName: PropTypes.string,
  params: PropTypes.shape({
    q: PropTypes.string,
    language: PropTypes.number,
  }),
});

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  searchText: PropTypes.string,
  searchTitle: PropTypes.string,
  searchProducts: PropTypes.func.isRequired,
  searchProductsClear: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  routePath: PropTypes.string,
  navigateTo: PropTypes.func.isRequired,
  trackProductClick: PropTypes.func.isRequired,
  noImageUrl: PropTypes.string,
  viewMode: PropTypes.string,
  routeData: routeDataPropType,
  prevRouteData: routeDataPropType,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.shape({
    boxThemeFontSize: PropTypes.string.isRequired,
  }).isRequired,
  style: PropTypes.object,
  ariaLabel: PropTypes.string,
};

const mapStateToProps = ({
  suggestions,
  settings: { product, search },
  routing: { routeData, previous },
}) => ({
  suggestions: suggestions.products,
  noImageUrl: product ? product.noImage.small : null,
  viewMode: search && search.defaultViewMode,
  routeData,
  prevRouteData: previous?.routeData,
});

export default connect(
  mapStateToProps,
  { searchProducts, searchProductsClear, navigateTo, trackProductClick },
)(SearchBox);

function getSuggestions(value, products, noImageUrl) {
  const escapedValue = escapeRegexCharacters(value.trim());
  const regex = new RegExp(`(${escapedValue})`, 'gi');

  return products
    .map(product => product.productGroup ? {
      ...product,
      id: product.productGroup.id,
      imageUrl: product.productGroup.imageUrl ?? noImageUrl,
      highlightedText: (product.id === 'show_more_products' || product.id === 'show_more_elements' || product.isParentItem) ? highlightStringBySpans(`${product.productGroup.title}`, regex, styles.highlight) : highlightStringBySpans(`${product.productGroup.title} - ${product.productGroup.id}`, regex, styles.highlight),
      /*highlightedText: (product.id === 'show_more_products' || product.id === 'show_more_elements' || product.isParentItem) ? highlightStringBySpans(`${product.productGroup.title || ''} - ${product.productGroup.id}`, regex, styles.highlight),*/
    } : {
      ...product,
        imageUrl: product.imageUrl ? product.imageUrl : noImageUrl,
        highlightedText: (product.id === 'show_more_products' || product.id === 'show_more_elements' || product.isParentItem) ? highlightStringBySpans(`${product.title}`, regex, styles.highlight) : highlightStringBySpans(`${product.title} - ${product.id}`, regex, styles.highlight),
        /*highlightedText: highlightStringBySpans(`${product.title} - ${product.id}`, regex, styles.highlight),*/
    });
}

function getItemValue(suggestion) {
  return suggestion.title;
}

function calculateMaxHeight(form) {
  const innerHeight = window.innerHeight;
  return innerHeight - form.getBoundingClientRect().bottom + 'px';
}