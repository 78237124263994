import { Breadcrumb as BreadcrumbPrimitive } from 'components/primitives/breadcrumb';
import { routesBuilder } from 'routes';
import { useSimpleTexts } from 'components/sanaText';
import { useIsMobileViewport } from 'utils/hooks';
import { BasketTypes } from 'behavior/basket/constants';
import { connect } from 'react-redux';

/*171082 - 3.5.Two shopping carts(orders and quotes)*/
const BasketBreadcrumb = ({ model }) => {
  const isMobile = useIsMobileViewport();
  const shoppingBasketHeader_Text = useSimpleTexts([!isMobile && 'ShoppingBasket_Header']).texts;
  const shoppingQuoteBasketHeader_Text = useSimpleTexts([!isMobile && 'ShoppingQuoteBasket_Header']).texts;

  if (isMobile || model == null)
    return null;

  /*171082 - 3.5.Two shopping carts(orders and quotes)*/
  const [shoppingBasketHeaderText] = model.basketType === BasketTypes.QUOTEBASKET ? shoppingQuoteBasketHeader_Text : shoppingBasketHeader_Text;

  const basketNode = {
    title: shoppingBasketHeaderText || '',
    link: { to: routesBuilder.forBasket() },
  };

  return <BreadcrumbPrimitive items={[basketNode]} />;
};

export default connect(({ basket: { model } }) => ({
  model: model,
}))(BasketBreadcrumb);
