import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import type { RouteData } from 'routes';
import type { Handler } from '../types';
import type { VisualDesingerCommonPage } from './types';

let handlerPromise: Promise<{ default: Handler<RouteData, VisualDesingerCommonPage> }>;
export default (...args: Parameters<Handler<RouteData, VisualDesingerCommonPage>>) => {
  if (!handlerPromise)
    handlerPromise = import(/*webpackChunkName:"vd"*/ './handler');

  return from(handlerPromise).pipe(
    switchMap(module => {
      const handler = module.default;
      return handler(...args);
    }));
};
