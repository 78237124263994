//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
import type {
    OrderTemplate,
} from '../orderTemplates/types';

export const ORDER_TEMPLATE_DETAILS_REQUESTED = 'ORDER_TEMPLATE_DETAILS_REQUESTED' as const;
export const loadOrderTemplateDetails = (id: string) => ({
    type: ORDER_TEMPLATE_DETAILS_REQUESTED,
    payload: { id },
});

export const ORDER_TEMPLATE_DETAILS_RECEIVED = 'ORDER_TEMPLATE_DETAILS_RECEIVED' as const;
export const orderTemplateDetailsReceived = (orderTemplates: OrderTemplate[] | null) => ({
    type: ORDER_TEMPLATE_DETAILS_RECEIVED,
    payload: orderTemplates ? orderTemplates[0] : null,
});
export type OrderTemplateDetailsReceivedAction = ReturnType<typeof orderTemplateDetailsReceived>;

export const ORDER_TEMPLATE_RESET_QUANTITY_REQUESTED = 'ORDER_TEMPLATE_RESET_QUANTITY_REQUESTED' as const;
export const resetQuantity = (id: string) => ({
    type: ORDER_TEMPLATE_RESET_QUANTITY_REQUESTED,
    payload: { id },
});

export const ORDER_TEMPLATE_RESETED_QUANTITY = 'ORDER_TEMPLATE_RESETED_QUANTITY' as const;
export const resetQuantityReseted = () => ({
    type: ORDER_TEMPLATE_RESETED_QUANTITY,
    payload: {},
});
export type OrderTemplateQuantityResetedAction = ReturnType<typeof resetQuantityReseted>;

export const ORDER_TEMPLATE_LINE_UOM_CHANGED = 'ORDER_TEMPLATE_LINE_UOM_CHANGED' as const;
export const orderTemplateLineUomChanged = (lineId: string, uomId: string) => ({
    type: ORDER_TEMPLATE_LINE_UOM_CHANGED,
    payload: { lineId, uomId },
});
export type OrderTemplateLineUomChangedAction = ReturnType<typeof orderTemplateLineUomChanged>;

export const ORDER_TEMPLATE_SHARE_CHANGED = 'ORDER_TEMPLATE_SHARE_CHANGED' as const;
export const orderTemplateShareChanged = () => ({
    type: ORDER_TEMPLATE_SHARE_CHANGED,
    payload: {},
});
export type OrderTemplateShareChangedAction = ReturnType<typeof orderTemplateShareChanged>;

export type OrderTemplateDetailsAction = ReturnType<
    | typeof loadOrderTemplateDetails
    | typeof orderTemplateDetailsReceived
    | typeof resetQuantity
    | typeof resetQuantityReseted
    | typeof orderTemplateShareChanged
    | typeof orderTemplateLineUomChanged
>;