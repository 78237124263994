import type { Options } from './types';
import { ReactNode, useMemo } from 'react';
import { useSanaTexts } from './hooks';

type Props = {
  children?: (texts: string[], props?: any) => ReactNode | null;
  options: Options;
  dontWait?: boolean;
  props?: any;
  placeholder?: ReactNode | null;
};

const UseSanaTexts = ({
  children,
  options,
  dontWait,
  props,
  placeholder = null,
}: Props): ReactNode | null => {
  const { texts, loaded } = useSanaTexts(options);

  return useMemo(() => {
    if (!dontWait && !loaded)
      return placeholder;

    if (!children)
      return null;

    if (props)
      return children(texts, props) || null;

    return children(texts) || null;
  }, [children, texts, dontWait, props]);
};

export default UseSanaTexts as (props: Props) => JSX.Element | null;
