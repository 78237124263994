import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';

const ListPage = loadable(() => import(/*webpackChunkName:"sub-acc"*/'./ListPage'));
const SubAccount = loadable(() => import(/*webpackChunkName:"sub-acc"*/'./Details'));

export default {
  [PageComponentNames.SubAccounts]: page => (
    <ListPage desktop={page.content?.desktop} mobile={page.content?.mobile} />
  ),
};

/* eslint-disable react/no-multi-comp */
export function subAccountRenderer(page) {
  return <SubAccount subAccount={page.subAccount} options={page.options} authorizers={page.authorizers} />;
}
