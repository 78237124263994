//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
import type { Handler } from '../types';
import type { OrderTemplate } from '../orderTemplates/types';
import { orderTemplateDetailsQuery } from './queries';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { orderTemplateDetailsReceived } from './actions';
import { RouteName } from 'routes';

const handler: Handler<OrderTemplateDetailsRouteData, OrderTemplateDetailsPage> = ({ params }, _state$, { api }) => {

    return api.graphApi<OrderTemplateDetailsPageResponse>(orderTemplateDetailsQuery, { id: params.id }).pipe(
        map(({ pages: { orderTemplateDetails: page }, orderTemplates }) => {

            if (!page || (orderTemplates && !orderTemplates.length))
                return null;

            return {
                page: { ...page, component: PageComponentNames.OrderTemplateDetails as const },
                action$: of(orderTemplateDetailsReceived(orderTemplates)),
            };
        }),
        initSystemPageContent(),
    );
};

export default handler;

type OrderTemplateDetailsPage = SystemPage & {
    component: PageComponentNames.OrderTemplateDetails;
};

type OrderTemplateDetailsRouteData = {
    routeName: RouteName.OrderTemplateDetails;
    params: { id: string };
};

type SystemPageResponse = {
    pages: {
        orderTemplateDetails: SystemPageData;
    };
};

type OrderTemplateDetailsPageResponse = SystemPageResponse & {
    orderTemplates: OrderTemplate[]
};