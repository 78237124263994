//CIL 2.6.CA Search
import loadable from '@loadable/component';

const CASearchResult = loadable(() => import('./CASearchResult'));

export function caSearchRender(_page) {
    return <CASearchResult />;
}


