import type { RoutingAction } from 'behavior/routing/actions';
import type { OrderTemplate, Product } from './types';
import {
  OrderTemplatesAddedAction,
  OrderTemplatesRemovedAction,
  OrderTemplatesReceivedAction,
  OrderTemplatesLinesReceivedAction,
  OrderTemplateAction,
  OrderTemplateReceiveProductsAction,
  OrderTemplateAllItemsSelectedAction,
  OrderTemplateProductsAddAction,
  ORDER_TEMPLATES_ADDED,
  ORDER_TEMPLATES_REMOVED,
  ORDER_TEMPLATES_RECEIVED,
  ORDER_TEMPLATE_LINES_RECEIVED,
  ORDER_TEMPLATE_PRODUCTS_RECEIVED,
  ORDER_TEMPLATE_CREATE_FAILED,
  ORDER_TEMPLATE_ALL_ITEMS_SELECTED,
  ORDER_TEMPLATE_ADD_PRODUCTS_RECEIVED,
  OrderTemplateReceivedErrorMessageAction,
} from 'behavior/pages/orderTemplates/actions';
import { createReducer } from 'utils/redux';
import { UpdateType } from './constants';
import { PageComponentNames } from '../componentNames';
import { arrayToObject } from 'utils/helpers';

type StatusAdded = {
  type: UpdateType.Added;
  countAdded: number;
};

type StatusRemoved = {
  type: UpdateType.Removed;
};

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
type State = {
  component: PageComponentNames.OrderTemplates;
  status?: StatusAdded | StatusRemoved;
  items?: OrderTemplate[] | null;
  orderTemplate?: OrderTemplate | null;
  products: Record<string, Product> | null;
  allItemsSelected: boolean;
  isFailed?: false;
};

export default createReducer<State, OrderTemplateAction | RoutingAction>(null as unknown as State, {
  [ORDER_TEMPLATES_ADDED]: onOrderTemplatesAdded,
  [ORDER_TEMPLATES_REMOVED]: onOrderTemplatesRemoved,
  [ORDER_TEMPLATES_RECEIVED]: onOrderTemplatesReceived,
  [ORDER_TEMPLATE_LINES_RECEIVED]: onOrderTemplateLinesReceived,
  [ORDER_TEMPLATE_PRODUCTS_RECEIVED]: onProductsReceived,
  [ORDER_TEMPLATE_CREATE_FAILED]: onOrderTemplateReceivedErrorMessage,
  [ORDER_TEMPLATE_ALL_ITEMS_SELECTED]: onAllItemsSelected,
  [ORDER_TEMPLATE_ADD_PRODUCTS_RECEIVED]: onProductsAddToCart,
});

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
function onOrderTemplateReceivedErrorMessage(state: State, action: OrderTemplateReceivedErrorMessageAction) {
    return {
        ...state,
        error: {
            ...action.payload,
        },
    };
}

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
function onProductsReceived(state: State, action: OrderTemplateReceiveProductsAction) {
    const productList = action.payload.products == null ? [] : action.payload.products;
    return {
        ...state,
        products: { ...state.products, ...arrayToObject(productList, (product: Product) => product.id) },
    };
}

function onProductsAddToCart(state: State, action: OrderTemplateProductsAddAction) {
    const productList = action.payload.products == null ? [] : action.payload.products;
    const orderTemplate = state.orderTemplate;
    const prodct = {
        id: action.payload.products[0].id,
        title: action.payload.products[0].title,
        exists: true,
        uoms: action.payload.products[0].uoms,
        url: action.payload.products[0].url,
        categoriesPaths: null,
        price: action.payload.products[0].price,
    };
    const subline = [];
    const subLin = {
        id: action.payload.products[0].id,
        uomId: null,
        quantity: null,
    };
    subline.push(subLin);
    const line = { product: prodct, subLines: subline, hasConfiguration: false, price: action.payload.products[0]?.price };
    orderTemplate?.lines?.push(line);
    return {
        ...state,
        products: { ...state.products, ...arrayToObject(productList, (product: Product) => product.id) },
    };
}

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
function onAllItemsSelected(state: State, action: OrderTemplateAllItemsSelectedAction) {
    return {
        ...state,
        allItemsSelected: action.payload.allItemsSelected,
    };
}

function onOrderTemplatesAdded(state: State, action: OrderTemplatesAddedAction) {
  return {
    ...state,
    status: {
      type: UpdateType.Added as const,
      countAdded: action.payload,
    },
  };
}

function onOrderTemplatesRemoved(state: State, action: OrderTemplatesRemovedAction) {
  const status = { type: UpdateType.Removed as const };
  if (!state.items)
    return { ...state, status };

  return {
    ...state,
    items: state.items.filter(t => !action.payload.includes(t.id)),
    status,
  };
}

function onOrderTemplatesReceived(state: State, action: OrderTemplatesReceivedAction) {
  return {
    ...state,
    items: action.payload,
  };
}

function onOrderTemplateLinesReceived(state: State, action: OrderTemplatesLinesReceivedAction) {
  if (!state.items)
    return state;

  const { id: templateId, orderTemplateLines } = action.payload;

  return {
    ...state,
    items: state.items.map(template => {
      if (template.id !== templateId)
        return template;

      return { ...template, lines: orderTemplateLines };
    }),
  };
}
