import { ProductReviewInput } from '../product/types';
import type { CalculatedProduct, ProductReview } from './types';

export const PRODUCTGROUP_CALCULATED_PRODUCTLIST_REQUESTED = 'PRODUCTGROUP_CALCULATED_PRODUCTLIST_REQUESTED' as const;
export const requestCalculatedProductList = (groupedProductIds: string[]) => ({
  type: PRODUCTGROUP_CALCULATED_PRODUCTLIST_REQUESTED,
  payload: { groupedProductIds },
});

export const PRODUCTGROUP_CALCULATED_PRODUCT_REQUESTED = 'PRODUCTGROUP_CALCULATED_PRODUCT_REQUESTED' as const;
export const requestCalculatedProduct = (groupedProductId: string, uomId: string) => ({
  type: PRODUCTGROUP_CALCULATED_PRODUCT_REQUESTED,
  payload: { groupedProductId, uomId },
});

export const PRODUCTGROUP_PRODUCTS_UPDATED = 'PRODUCTGROUP_PRODUCTS_UPDATED' as const;
export const productsUpdated = (products: CalculatedProduct[] | null) => ({
  type: PRODUCTGROUP_PRODUCTS_UPDATED,
  payload: products,
});

//Ticket 171069: Cambridge Isotope 3.3. Redesign � product group page
export const GROUPREVIEWS_REQUESTED = 'GROUPPRODUCT/REVIEWS/REQUESTED' as const;
export const requestGroupReviews = (productId: string, pageIndex: number) => ({
    type: GROUPREVIEWS_REQUESTED,
    payload: { productId, pageIndex },
});

//Ticket 171069: Cambridge Isotope 3.3. Redesign � product group page
export const GROUP_REVIEWS_RECEIVED = 'GROUPPRODUCT/REVIEWS/RECEIVED' as const;
export const groupReviewsReceived = (reviews: ProductReview[]) => ({
    type: GROUP_REVIEWS_RECEIVED,
    payload: reviews,
});
//Ticket 171069: Cambridge Isotope 3.3. Redesign � product group page
export type GroupReviewsReceivedAction = ReturnType<typeof groupReviewsReceived>;

//Ticket 171069: Cambridge Isotope 3.3. Redesign � product group page
export const REVIEWS_SUBMITTED = 'PRODUCT/REVIEWS/SUBMITTED' as const;
export const sendReview = (data: ProductReviewInput) => ({ type: REVIEWS_SUBMITTED, payload: data });

//Ticket 171069: Cambridge Isotope 3.3. Redesign � product group page
export const REVIEWS_PROCESSED = 'PRODUCT/REVIEWS/PROCESSED' as const;
export const reviewsProcessed = (success: boolean) => ({
    type: REVIEWS_PROCESSED,
    payload: success,
});
export type ReviewProcessedAction = ReturnType<typeof reviewsProcessed>;

export type ProductGroupAction = ReturnType<
  | typeof requestCalculatedProductList
  | typeof requestCalculatedProduct
  | typeof productsUpdated
  | typeof sendReview
  | typeof reviewsProcessed
  | typeof groupReviewsReceived
  | typeof requestGroupReviews
>;
