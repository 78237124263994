//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
import type { RoutingAction } from 'behavior/routing/actions';
import type { OrderTemplate, OrderTemplateLine } from '../orderTemplates/types';
import {
  OrderTemplateDetailsAction,
  ORDER_TEMPLATE_RESETED_QUANTITY,
  OrderTemplateQuantityResetedAction,
  ORDER_TEMPLATE_SHARE_CHANGED,
  ORDER_TEMPLATE_LINE_UOM_CHANGED,
  OrderTemplateLineUomChangedAction,
  OrderTemplateShareChangedAction,
} from 'behavior/pages/orderTemplateDetails/actions';
import { createReducer } from 'utils/redux';
import { PageComponentNames } from '../componentNames';

type State = {
  component: PageComponentNames.OrderTemplateDetails;
    item: OrderTemplate | null;
    orderTemplate: OrderTemplate | null;
};

export default createReducer<State, OrderTemplateDetailsAction | RoutingAction>(null as unknown as State, {  
  [ORDER_TEMPLATE_RESETED_QUANTITY]: onQuantityReseted,
  [ORDER_TEMPLATE_SHARE_CHANGED]: onOrderTemplateShareChanged,
  [ORDER_TEMPLATE_LINE_UOM_CHANGED]: onOrderTemplateLineUomChanged,
});

function onOrderTemplateShareChanged(state: State, action: OrderTemplateShareChangedAction) {
    if (!state.orderTemplate)
        return state;

    return {
        ...state, orderTemplate: { ...state.orderTemplate, shared: !state.orderTemplate.shared },
    };
}

function onOrderTemplateLineUomChanged(state: State, action: OrderTemplateLineUomChangedAction) {
    if (!state.orderTemplate || !state.orderTemplate.lines)
        return state;

    const { lines: lines } = state.orderTemplate;
    let changed = false;

    const updatedLines = lines.map(line => {
        const newSubLines = line.subLines.map(subLine => {
            if (subLine.id !== action.payload.lineId)
                return subLine;

            subLine.uomId = action.payload.uomId;
            changed = true;

            return { ...subLine, uomId: action.payload.uomId };
        });

        return { ...line, subLines: newSubLines };
    });

    return changed
        ? { ...state, orderTemplate: { ...state.orderTemplate, lines: updatedLines } }
        : state;
}

function onQuantityReseted(state: State, action: OrderTemplateQuantityResetedAction) {
    if (!state.orderTemplate || !state.orderTemplate.lines)
        return state;
    let lins: OrderTemplateLine[] = [];
    for (let line of state.orderTemplate.lines) {
    line.subLines.forEach(s => s.quantity = 0);    
    lins.push(line);
  }

  return {
    ...state,
      orderTemplate: { ...state.orderTemplate, lines: lins},
    };
}
