//CIL 2.7.SDS Search
import loadable from '@loadable/component';

const SDSSearchResult = loadable(() => import('./SDSSearchResult'));

export function sdsSearchRender(_page) {
    return <SDSSearchResult />;
}


