//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
import type { AppState } from 'behavior';
import type { Epic } from 'behavior/types';
import type { OrderTemplate } from '../orderTemplates/types';
import type { ModifiedLines } from 'behavior/basket/types';
import {
    orderTemplateDetailsQuery,
} from './queries';
import {
    ORDER_TEMPLATE_DETAILS_REQUESTED,
    orderTemplateDetailsReceived,
    OrderTemplateDetailsAction,
} from './actions';
import { mergeMap, map, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';

type OrderTemplateDetailsResponse = {
    orderTemplates: OrderTemplate[] | null;
};

const loadOrderTemplateDetailsEpic: Epic<OrderTemplateDetailsAction> = (action$, state$, { api, logger }) => {
    const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

    return action$.pipe(
        ofType(ORDER_TEMPLATE_DETAILS_REQUESTED),
        mergeMap(action => api.graphApi<OrderTemplateDetailsResponse>(orderTemplateDetailsQuery, {
            ...action.payload,
            loadCategories: state$.value.analytics?.isTrackingEnabled,
        }).pipe(
            map(({ orderTemplates }) =>
                orderTemplateDetailsReceived(orderTemplates)),
            retryWithToast(action$, logger),
            takeUntil(locationChanged$),
        ),
        ),
    );
};

export default combineEpics(
    loadOrderTemplateDetailsEpic,
);
