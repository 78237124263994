import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import type { Api } from 'utils/api';
import type { StateObservable } from 'redux-observable';
import type { AppState } from 'behavior';
import { PageComponentNames } from 'behavior/pages';
import { RouteName } from 'routes';
import { getBackTo } from '../helpers';
import { map, switchMap, pluck, first } from 'rxjs/operators';
import { StoreType, areSettingsLoaded } from 'behavior/settings';
import { LoginPage } from './types';
import { ContentSystemPageData, initContentSystemPage, loadContentSystemPageQuery } from '../system';

const handler: Handler<LoginRouteData, LoginPage> = (routeData, state$, { api }) => {
  return state$.pipe(
    pluck('settings'),
    first(areSettingsLoaded),
    switchMap(settings => {
      const shouldLoadClosedStorePage = routeData.params?.previewToken
        ? routeData.params.closedStore
        : settings.storeType === StoreType.Closed;

      return shouldLoadClosedStorePage
        ? loadClosedStoreLoginPage(routeData, state$, api)
        : loadSystemLoginPage(routeData, state$, api);
    }),
  );
};

export default handler;

function loadClosedStoreLoginPage(routeData: LoginRouteData, state$: StateObservable<AppState>, api: Api) {
  return api.graphApi<ClosedStoreLoginPageResponse>(loadContentSystemPageQuery('closedStoreLogin')).pipe(
    map(({ pages: { closedStoreLogin } }) => ({
      page: {
        ...initContentSystemPage(closedStoreLogin),
        component: PageComponentNames.ClosedStoreLogin as const,
        emptyLayout: true as const,
        backTo: _getBackTo(routeData, state$),
      },
    })),
  );
}

function loadSystemLoginPage(routeData: LoginRouteData, state$: StateObservable<AppState>, api: Api) {
  return api.graphApi<LoginPageResponse>(loadContentSystemPageQuery('login')).pipe(
    map(({ pages: { login } }) => ({
      page: {
        ...initContentSystemPage(login),
        component: PageComponentNames.Login as const,
        backTo: routeData.options && routeData.options.backTo || _getBackTo(routeData, state$),
      },
    })),
  );
}

function _getBackTo(routeData: LoginRouteData, state$: StateObservable<AppState>) {
  return getBackTo(state$, [
    RouteName.Login,
    RouteName.Registration,
    RouteName.ResetPassword,
    RouteName.ForgotPassword,
  ], routeData.params && routeData.params.language);
}

type LoginRouteData = {
  routeName: RouteName.Login;
  params?: {
    language: number;
    previewToken?: string;
    closedStore?: boolean;
  };
  options?: {
    backTo?: BackTo;
  };
};

type LoginPageResponse = {
  pages: {
    login: ContentSystemPageData;
  };
};

type ClosedStoreLoginPageResponse = {
  pages: {
    closedStoreLogin: ContentSystemPageData;
  };
};
