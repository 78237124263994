//CIL 2.6 2.7 Search
import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';

export default (_routeData, _state$, _) => {
  return of({
    page: {
      component: PageComponentNames.AttachmentSearchMain,
    },
  });
};
