import styles from './Summary.module.scss';
import PropTypes from 'prop-types';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { Link, LinkButton } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { FooterLinkStyleOption } from './constants';
import { Button } from 'components/primitives/buttons';
import { changeBasketType, changeBasketTypeRedirectToCheckOut } from 'behavior/basket';
import { useDispatch } from 'react-redux';
import { BasketTypes } from './constants';

//171082 - 3.5.Two shopping carts(orders and quotes)
const FooterLink = ({ style, textKey, basketType, isShoppingCart, ...props }) => {

  const dispatch = useDispatch();
  const onClickShoppingCart = isShoppingCart ? () => dispatch(changeBasketType(basketType)) : ((basketType === BasketTypes.basket) ? () => dispatch(changeBasketTypeRedirectToCheckOut(basketType, false)) : () => dispatch(changeBasketTypeRedirectToCheckOut(basketType, true)) );

  switch (style) {
    case FooterLinkStyleOption.ShowAsLink:
      return (
        <Button  onClick={onClickShoppingCart} {...props}>
          <SimpleText textKey={textKey} />
        </Button>
      );
    case FooterLinkStyleOption.ShowAsButton:
      return (
        <Button className={styles.footerBtn} onClick={onClickShoppingCart} {...props}>
          <SimpleText textKey={textKey} />
        </Button>
      );
    case FooterLinkStyleOption.ShowAsActionButton:
      return (
        <Button className={styles.footerBtn} theme="action" onClick={onClickShoppingCart} {...props}>
          <SimpleText textKey={textKey} />
        </Button>
      );
    default:
      return null;
  }
};

FooterLink.propTypes = {
  style: PropTypes.number.isRequired,
  textKey: PropTypes.string.isRequired,
  basketType: PropTypes.string.isRequired,
};

export default FooterLink;
