import styles from './Search.module.scss';
import PropTypes from 'prop-types';
import { getThemeFontSizeClassName } from 'components/theme';
import { Link } from 'components/primitives/links';
import { useHeaderContext } from 'components/sections/headerContext';

const SuggestionItem = ({ suggestion, options }) => {
  let categories;
  if (suggestion.categoriesPaths?.length)
    categories = suggestion.categoriesPaths[0].categories;
  const headerContext = useHeaderContext(); /*2.4 Search � Content search results - #183781*/

  /*2.4 Search � Content search results - #183781*/
  if (suggestion.id === 'show_more_products' || suggestion.id === 'show_more_elements') {
    const url = suggestion.id === 'show_more_products' ? '/search?q=' + headerContext.searchInputValue + '&isContent=0' : '/search?q=' + headerContext.searchInputValue + '&isContent=1';
    return (
      <span className={styles.suggestItem}>
        <Link url={url}>
          <span className={styles.img}>
            {((suggestion.id === 'show_more_products' || suggestion.id === 'show_more_elements') ? '' : <img src={suggestion.imageUrl} alt={suggestion.title} />)}
          </span>
          <span className={styles.text}>
            <span className={styles.titleId}>
              {suggestion.highlightedText}
            </span>
            <span className={styles.category} />
          </span>
        </Link>
      </span>
    );
  }
  else if (suggestion.isContent === 'True') {
    return (
      /*2.4 Search � Content search results - #183781*/
      <span className={`${styles.suggestItem} ${getThemeFontSizeClassName(options.suggestionsThemeFontSize)}`}>

        {options.showThumbnails && (
          <span className={styles.img}>
            {/* <img src='./images/Content.png' alt="" role="presentation" /> */}
          </span>
        )}

        <span className={styles.text}>
          <span className={styles.titleId}>
            {suggestion.title}
          </span>
          <span className={styles.category}>
            {categories?.length ? categories[categories.length - 1].name : null}
          </span>
        </span>
      </span>
    );
  }
  else {
    return (
      /*2.4 Search � Content search results - #183781*/
      <span className={`${styles.suggestItem} ${getThemeFontSizeClassName(options.suggestionsThemeFontSize)}`}>
        {options.showThumbnails && (
          <span className={styles.img}>
            <img src={suggestion.imageUrl} alt="" role="presentation" />
          </span>
        )}
        <span className={styles.text}>
          <span className={styles.titleId}>
            {suggestion.highlightedText}
          </span>
          <span className={styles.category}>
            {categories?.length ? categories[categories.length - 1].name : null}
          </span>
        </span>
      </span>
    );
    //return (
    //  /*2.4 Search � Content search results - #183781*/
    //  <span className={`${styles.suggestItem} ${getThemeFontSizeClassName(options.suggestionsThemeFontSize)}`}>       
    //    <span className={styles.text}>
    //      <span className={styles.titleId}>
    //        {suggestion.isContent === 'True' ? suggestion.title : suggestion.highlightedText}
    //      </span>
    //    </span>
    //  </span>
    //);
  }

};

SuggestionItem.propTypes = {
  suggestion: PropTypes.shape({
    imageUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    highlightedText: PropTypes.node.isRequired,
    isContent: PropTypes.string,
    contentUrl: PropTypes.string,
    categoriesPaths: PropTypes.arrayOf(
      PropTypes.shape({
        categories: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
        })),
      }),
    ),
  }).isRequired,
  options: PropTypes.shape({
    showThumbnails: PropTypes.bool.isRequired,
    suggestionsThemeFontSize: PropTypes.string.isRequired,
  }).isRequired,
};

export default SuggestionItem;
