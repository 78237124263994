import { generateKey } from 'utils/helpers';

export const subAccounts = {
  list: Array.from(Array(5)).map(() => ({
    id: generateKey(),
    name: '',
    email: '',
    active: true,
    canAuthorizeOrders: false,
    canOrder: true,
    canSeePrices: true,
    canSeeStock: true,
    limits: [],
    dependentAccounts: [],
  })),
};
