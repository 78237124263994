//Ticket 171069: Cambridge Isotope 3.3. Redesign � product group page
import type { Epic } from 'behavior/types';
import { ProductGroupAction, reviewsProcessed, groupReviewsReceived, GROUPREVIEWS_REQUESTED } from './actions';
import type { LoadedSettings, Settings } from 'behavior/settings';
import { ofType } from 'redux-observable';
import { mergeMap, map, pluck, concatMap, catchError, startWith, filter, switchMap, takeUntil, exhaustMap } from 'rxjs/operators';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import {
  PRODUCTGROUP_CALCULATED_PRODUCTLIST_REQUESTED,
  PRODUCTGROUP_CALCULATED_PRODUCT_REQUESTED,
  REVIEWS_SUBMITTED,
  productsUpdated,
} from './actions';
import { createCalculatedProductsQuery, specificationsSettingsQuery } from './queries';
import { merge, of, throwError } from 'rxjs';
import { settingsLoaded, setUpdating } from 'behavior/settings';
import { NAVIGATING } from 'behavior/routing';
import { addReview, reviewsQuery } from '../product/queries';
import { resetCaptcha } from '../../captcha';
import { FormName, unlockForm } from '..';
import { LOCATION_CHANGED } from 'behavior/events';
import { ProductReview } from './types';

const productGroupEpic: Epic<ProductGroupAction> = (action$, state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const specificationsSettings$ = action$.pipe(
    ofType(PRODUCTGROUP_CALCULATED_PRODUCTLIST_REQUESTED),
    filter(_ => {
      const settings = state$.value.settings as LoadedSettings;
      return settings.product.productGrouping.isEnabled && !settings.product.productGrouping.specifications;
    }),
    mergeMap(_ => api.graphApi<SettingsResponse>(specificationsSettingsQuery).pipe(
      pluck('settings'),
      map(settingsLoaded),
      catchError(e => merge(of(settingsLoaded()), throwError(e))),
      startWith(setUpdating())),
    ),
  );

  const calculatedSingleProduct$ = action$.pipe(
    ofType(PRODUCTGROUP_CALCULATED_PRODUCT_REQUESTED),
    pluck('payload'),
    switchMap(({ groupedProductId, uomId }) => {
      const options = {
        ids: [groupedProductId],
        uomId,
        ignoreGrouping: true,
      };
      const query = createCalculatedProductsQuery(state$.value.insiteEditor.initialized);

      return api.graphApi<CalculatedProductsResponse>(query, { options }).pipe(
        pluck('catalog', 'products', 'products'),
        map(productsUpdated),
        retryWithToast(action$, logger),
      );
    }),
  );

  const navigating$ = action$.pipe(ofType(NAVIGATING));

  const calculatedProductList$ = action$.pipe(
    ofType(PRODUCTGROUP_CALCULATED_PRODUCTLIST_REQUESTED),
    pluck('payload'),
    mergeMap(data => of(data).pipe(
      mergeMap(({ groupedProductIds }) => {
        const settings = state$.value.settings as LoadedSettings;
        const productsPerBatch = settings.productList.listProductAmount;
        const query = createCalculatedProductsQuery(state$.value.insiteEditor.initialized);

        const optionBatches: {
          ids: string[];
          page: {
            size: number;
          };
          ignoreGrouping: true;
          query: string;
        }[] = [];

        const idsCopy = [...groupedProductIds];

        for (let index = 0; index < idsCopy.length; index + productsPerBatch) {
          const batchIds = idsCopy.splice(index, productsPerBatch);
          optionBatches.push({
            ids: batchIds,
            page: {
              size: productsPerBatch,
            },
            ignoreGrouping: true,
            query,
          });
        }

        return optionBatches;
      }),
      concatMap(({ query, ...options }) => api.graphApi<CalculatedProductsResponse>(query, { options }).pipe(
        pluck('catalog', 'products', 'products'),
        map(productsUpdated),
        retryWithToast(action$, logger),
      )),
      takeUntil(navigating$),
    )),
    );

    const onReviewsRequested$ = action$.pipe(
        ofType(GROUPREVIEWS_REQUESTED),
        exhaustMap(action => api.graphApi<ProductReviewsResponse>(reviewsQuery, action.payload).pipe(
            map(r => r.catalog?.products.products[0].reviews?.list),
            filter(r => !!r?.length),
            map(r => groupReviewsReceived(r!)),
            takeUntil(locationChanged$),
        )),
    );

    const reviewProcessedAction = reviewsProcessed(true);
    const onReviewSubmitted$ = action$.pipe(
        ofType(REVIEWS_SUBMITTED),
        exhaustMap(action => api.graphApi(addReview, { data: action.payload }).pipe(
            mergeMap(_ => [reviewProcessedAction, resetCaptcha(FormName.GroupPageReview), unlockForm(FormName.GroupPageReview)]),
            catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(FormName.GroupPageReview), unlockForm(FormName.GroupPageReview))),
            retryWithToast(action$, logger, _ => of(resetCaptcha(FormName.GroupPageReview), unlockForm(FormName.GroupPageReview))),
            takeUntil(locationChanged$),
        )),
    );

    return merge(specificationsSettings$, calculatedProductList$, calculatedSingleProduct$, onReviewSubmitted$, onReviewsRequested$);
};

export default productGroupEpic;

type SettingsResponse = {
  settings: Partial<Settings>;
};

export type ProductReviewsResponse = {
    catalog: {
        products: {
            products: [{
                reviews: {
                    list: ProductReview[];
                } | null;
            }];
        };
    } | null;
};

type CalculatedProductsResponse = {
  catalog: {
    products: {
      products: Array<CalculatedProductResponse>;
    };
  };
};

type CalculatedProductResponse = {
  id: string;
  price: number | null;
  listPrice: number | null;
  inventory: number | null;
  isOrderable: boolean | null;
  uom: {
    id: string;
  } | null;
  variantComponentGroups: Array<{
    id: string;
  }>;
  specifications: Array<{
    key: string;
    name: string;
    value: string | null;
    titleTextKey?: string;
    valueTextKey?: string;
  }>;
};
