export const enum Preset {
  Details = 'Details',
  DetailsWithMatrix = 'DetailsWithMatrix',
}

export const enum ProductMediaType {
  Image = 'ProductImage',
  Video = 'ProductVideo',
}

//3.2.Redesign � product details page.
export const hazardPictogramsImagesPath = '/content/files/images/pictogramimages/';
