import type { Epic } from 'behavior/types';
import { switchMap, map, pluck } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { SDSSEARCH_REQUESTED, SDSSearchRequestedAction, receiveSDSSearch } from './action';
import { sDsSearchQuery } from './queries';

  const epic: Epic<SDSSearchRequestedAction> = (action$, _, { api }) => action$.pipe(
      ofType(SDSSEARCH_REQUESTED),
      pluck('payload', 'itemNo'),
      switchMap(itemNo => api.graphApi(sDsSearchQuery, { itemNo }).pipe(
        map(receiveSDSSearch),
      )),
    );
  
  export default epic;

