import styles from './Summary.module.scss';
import { useEffect, useState, useRef, useMemo } from 'react';
import { joinClasses } from 'utils/helpers';
import { AbilityState } from 'behavior/user/constants';
import PropTypes from 'prop-types';
import { connect, useSelector} from 'react-redux';
import { useHeaderContext } from 'components/sections/headerContext';
import { requestBasketSummary, basketHover } from 'behavior/basket';
import LoadingIndicator from './LoadingIndicator';
import Popup from './Popup';
import PopupQuote from './PopupQuote';
import { Dropdown } from 'components/primitives/dropdown';
import Header from './Header';
import HeaderQuote from './HeaderQuote';
import PreloadTexts from './PreloadTexts';
import withAbilitiesAndRouteChecker from './withAbilitiesAndRouteChecker';
import { throttle } from 'lodash';
import { getThemeFontSizeClassName } from 'components/theme';
import { BasketTypes, ActiveBasketType, ShopAccountType } from 'behavior/basket/constants';

const Summary = ({
  basket,
  updatingBy,
  basketButtonClicked,
  updatedLinesAmount,
  requestBasketSummary,
  basketHover,
  abilities,
  id,
  isDesignerMode,
  options,
  style,
  headerStyle,
}) => {
  const headerContext = useHeaderContext();
  const [dropdownOptions, setDropdownOptions] = useState({ show: false });
  const { canViewPrices, popupAbilities } = useMemo(() => {
    const [viewPriceAbility, ...popupAbilities] = abilities;
    return { canViewPrices: viewPriceAbility === AbilityState.Available, popupAbilities };
  }, [abilities]);
  const [linesAmount, setLinesAmount] = useState(null);
  const timeoutId = useRef();
  const showTotals = options.showSubtotal && canViewPrices;
  const [basketSummary, setBasketSummary] = useState(basket);
  const throttledSetBasketSummary = useThrottle(setBasketSummary, 300);
  const throttledRequestBasketSummary = useThrottle(requestBasketSummary, 750);
  const popupHovered = useRef();
  const isFirstRender = useRef(true);
  /*203597 - [CIL] Cambridge Isotope: Phase 3 - Contacts Registration process update*/
  const isProspect = useSelector(state => state.user.shopAccountType) !== ShopAccountType.Prospect;
  const isCalculatedProductItems = useSelector(state => state.settings?.basket?.calculateNumberOfProducts);

  useEffect(() => {
    if (isFirstRender.current)
      return;

    throttledSetBasketSummary(basket);
  }, [basket]);

  useEffect(() => {
    const basketNotExpired = basketSummary && !basketSummary.expired;

    if (basketNotExpired && (basketSummary?.calculated || !basketSummary?.calculated && !showTotals))
      return;

    if (basketSummary && basketSummary.expired && basketSummary.loaded && !basketSummary.loading)
      throttledRequestBasketSummary(!!showTotals);
    else
      requestBasketSummary(!!showTotals);
  }, [basketSummary, showTotals]);

  useEffect(() => {
    if (isFirstRender.current && (headerContext.available && !headerContext.isSummaryOpened))
      return;

    if (!basketSummary || (basketSummary && basketSummary.expired))
      return;

    const { productLines } = basketSummary;
    if (!updatedLinesAmount || !productLines || productLines.list.length === 0) {
      clearTimeout(timeoutId.current);
      setDropdownOptions({ show: false });
      return;
    }

    setLinesAmount(basketSummary.loaded && !popupHovered.current ? updatedLinesAmount : null);
    setDropdownOptions({ show: true });

    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      basketSummary.loaded && setDropdownOptions({ show: false });
      setLinesAmount(null);
    }, 2000);
  }, [basketSummary && basketSummary.loaded, updatedLinesAmount]);

  useEffect(() => {
    if (isFirstRender.current && !headerContext.isSummaryOpened)
      return;

    if (basketSummary && basketSummary.loading)
      setDropdownOptions({ show: true });
  }, [basketSummary && basketSummary.loading, updatingBy]);

  useEffect(() => {
    if (isFirstRender.current)
      return;

    if (basketSummary && basketSummary.totalCount)
      return;

    setDropdownOptions({ show: false });
  }, [basketSummary && !!basketSummary.totalCount]);

  useEffect(() => {
    headerContext.available && (headerContext.isSummaryOpened = dropdownOptions.show);
  }, [dropdownOptions]);

  useEffect(() => {
    isFirstRender.current = false;

    return () => {
      throttledSetBasketSummary.cancel();
      clearTimeout(timeoutId.current);
    };
  }, []);

  if (!basketSummary)
    return null;

  /*171082 - 3.5.Two shopping carts(orders and quotes)*/
  const { totalCount,totalCountInfo, loading, isAvailable, otherTotalCount,otherTotalCountInfo, basketType, productLines, otherProductLines } = basketSummary;
  let orderCartCount=0;
  let quoteCartCount = 0;
  let cartProductLines;
  let cartOtherProductLines;

  if (basketType === BasketTypes.BASKET) {
    orderCartCount = isCalculatedProductItems? totalCountInfo : totalCount;
    quoteCartCount = isCalculatedProductItems?  otherTotalCountInfo : otherTotalCount;
    cartProductLines = productLines;
    cartOtherProductLines = otherProductLines;
  }
  else if (basketType === BasketTypes.QUOTEBASKET) {
    quoteCartCount =  isCalculatedProductItems? totalCountInfo : totalCount;
    orderCartCount = isCalculatedProductItems?  otherTotalCountInfo : otherTotalCount;
    cartProductLines = otherProductLines;
    cartOtherProductLines = productLines;
  }

  let onHover = null;
  let onDropDownHide = null;

  if (!options.compact) {
    onHover = () => {
      clearTimeout(timeoutId.current);
      setLinesAmount(null);
      popupHovered.current = true;
      basketHover(ActiveBasketType.ALL);/*171082 - 3.5.Two shopping carts(orders and quotes)*/
    };
    onDropDownHide = () => {
      clearTimeout(timeoutId.current);
      setLinesAmount(null);
      popupHovered.current = false;
    };
  }

  return (
    <div
      id={id}
      className={joinClasses(
        styles.summary,
        !orderCartCount && styles.isEmpty,/*171082 - 3.5.Two shopping carts(orders and quotes)*/
        options.compact && styles.compact,
      )}
      onMouseOver={onHover}
      onFocus={onHover}
      role="presentation"
    >
      {options.compact
        ? (
          <>
            {isProspect &&
              <Header
                totalCount={orderCartCount}/*171082 - 3.5.Two shopping carts(orders and quotes)*/
                showFixed={headerContext.available && dropdownOptions.show && !loading}
                options={options}
                style={headerStyle}
              />
            }
            {loading && <LoadingIndicator />}
            <HeaderQuote
              totalCount={quoteCartCount}
              showFixed={headerContext.available && dropdownOptions.show && !loading}
              options={options}
              style={headerStyle}
            />
          </>
        )
        : (
          <>
            {isProspect &&
              <div className={`${styles.orderProductWrapper}`}>
                <Dropdown
                  bodyAlign="right"
                  options={dropdownOptions}
                  className={joinClasses(
                    styles.dropdown,
                    !orderCartCount && styles.isEmpty,
                    getThemeFontSizeClassName(options.contentThemeFontSize),
                  )}
                  header={<Header totalCount={orderCartCount} options={options} />}
                  onDropDownHide={onDropDownHide}
                  hideCaretDown={!options.showExpandIcon || !orderCartCount}
                  disableAnimation
                  style={style}
                >
                  {!isDesignerMode && (
                    <>
                      {loading && basketButtonClicked === ActiveBasketType.ORDER && <LoadingIndicator />}
                      {!loading && isAvailable && orderCartCount > 0 && (basketButtonClicked === ActiveBasketType.ORDER || basketButtonClicked === ActiveBasketType.ALL) && (
                        <Popup
                          linesAmount={linesAmount}
                          basket={basketSummary}
                          showTotals={showTotals}
                          abilities={popupAbilities}
                          options={options}
                          productLines={cartProductLines}
                          isCalculatedProductItems = {isCalculatedProductItems}
                        />
                      )}
                    </>
                  )}
                </Dropdown>
              </div>
            }
            <div className={`${styles.quoteProductWrapper}`}>
          <Dropdown
            bodyAlign="right"
            options={dropdownOptions}
            className={joinClasses(
              styles.dropdown,
              !quoteCartCount && styles.isEmpty,
              getThemeFontSizeClassName(options.contentThemeFontSize),
            )}
            header={<HeaderQuote totalCount={quoteCartCount} options={options} />}
            onDropDownHide={onDropDownHide}
            hideCaretDown={!options.showExpandIcon || !quoteCartCount}
            disableAnimation
            style={style}
          >
            {!isDesignerMode && (
              <>
                {loading && basketButtonClicked === ActiveBasketType.QUOTE && <LoadingIndicator />}
                {!loading && isAvailable && quoteCartCount > 0 && (basketButtonClicked === ActiveBasketType.QUOTE || basketButtonClicked === ActiveBasketType.ALL) && (
                  <PopupQuote
                    linesAmount={linesAmount}
                    basket={basketSummary}
                    showTotals={showTotals}
                    abilities={popupAbilities}
                    options={options}
                    productLines={cartOtherProductLines}
                    isCalculatedProductItems = {isCalculatedProductItems}
                  />
                )}
              </>
            )}
              </Dropdown>
            </div>
          </>
        )
      }
      <PreloadTexts />
    </div>
  );
};

Summary.propTypes = {
  basket: PropTypes.shape({
    totalCount: PropTypes.number,
    expired: PropTypes.bool,
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    isAvailable: PropTypes.bool,
    otherTotalCount: PropTypes.number, /*171082 - 3.5.Two shopping carts(orders and quotes)*/
  }),
  updatingBy: PropTypes.string,
  basketButtonClicked: PropTypes.string,
  updatedLinesAmount: PropTypes.number,
  requestBasketSummary: PropTypes.func.isRequired,
  basketHover: PropTypes.func.isRequired,
  abilities: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  isDesignerMode: PropTypes.bool,
  options: PropTypes.shape({
    compact: PropTypes.bool.isRequired,
    showExpandIcon: PropTypes.bool,
    contentThemeFontSize: PropTypes.string,
    showSubtotal: PropTypes.bool,
  }).isRequired,
  style: PropTypes.object,
  headerStyle: PropTypes.object,
};

export default connect(({ basket: { summary, updatingBy, basketButtonClicked , updated } }) => ({
  basket: summary,
  updatingBy,
  basketButtonClicked,
  updatedLinesAmount: updated.linesAmount,
}), { requestBasketSummary, basketHover })(withAbilitiesAndRouteChecker(Summary));

function useThrottle(func, wait) {
  const throttleRef = useRef();

  if (throttleRef.current == null)
    throttleRef.current = throttle(func, wait);

  return throttleRef.current;
}
