import type { Epic } from 'behavior/types';
import { switchMap, map, pluck } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CASEARCH_REQUESTED, CASearchRequestedAction, receiveCASearch } from './action';
import { caSearchQuery } from './queries';

  const epic: Epic<CASearchRequestedAction> = (action$, _, { api }) => action$.pipe(
      ofType(CASEARCH_REQUESTED),
      pluck('payload', 'batchNo'),
      switchMap(batchNo => api.graphApi(caSearchQuery, { batchNo }).pipe(
        map(receiveCASearch),
      )),
    );
  
  export default epic;

