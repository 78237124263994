import styles from './LinkButton.module.scss';
import { themeVarPrefix } from 'css/exports/_theme_vars.scss';
import PropTypes from 'prop-types';
import { LinkButton } from 'components/primitives/links';
import { useSimpleTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';

const LinkButtonBlock = ({ model }) => {
  const style = {
    color: model.button_Color,
    [`${themeVarPrefix}button_BackgroundColor`]: model.button_BackgroundColor,
    [`${themeVarPrefix}button_Hover_BackgroundColor`]: model.button_Hover_BackgroundColor,
    [`${themeVarPrefix}button_BottomBorderColor`]: model.button_BottomBorderColor,
    [`${themeVarPrefix}button_Hover_BottomBorderColor`]: model.button_Hover_BottomBorderColor,
  };

  return (
    <LinkButton
      url={model.link?.url}
      to={model.link?.to}
      target={model.link?.target}
      aria-label={model.buttonAltText}
      className={styles.element}
      style={style}
    >
      {model.isSanaText
        ? useSimpleTexts([model.buttonText], makeRichText).texts[0]
        : model.buttonText
      }
    </LinkButton>
  );
};

LinkButtonBlock.propTypes = {
  model: PropTypes.shape({
    buttonText: PropTypes.string,
    buttonAltText: PropTypes.string,
    link: PropTypes.object,
    button_Color: PropTypes.string,
    button_BackgroundColor: PropTypes.string,
    button_Hover_BackgroundColor: PropTypes.string,
    button_BottomBorderColor: PropTypes.string,
    button_Hover_BottomBorderColor: PropTypes.string,
  }),
  isDesignerMode: PropTypes.bool,
};

export default LinkButtonBlock;
