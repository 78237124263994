import type { VisualDesignerMode } from 'behavior/visualDesigner';
import type { ContentBlocksInfo, Errors, ViewData } from '../types';

type CallbackAction = { type: string; payload?: any };

export const modifyPageTitle = (pageTitle: { title: string | null } | { showTitle: boolean }) => ({
  type: 'VD/MODIFY_PAGE_TITLE' as const,
  payload: { pageTitle },
});

export const moveContentElement = (elementData: { indexBefore: number; indexAfter: number } | { indexBefore: number; indexAfter: number; sourceElementId: string; targetElementId: string }) => ({
  type: 'VD/MOVE_CONTENT_ELEMENT' as const,
  payload: elementData,
});

export const requestVisualDesignerDelete = (description: string, callbackAction: CallbackAction, frontendCallbackAction: CallbackAction) => ({
  type: 'VD/DELETE_REQUESTED' as const,
  payload: { description, callbackAction, frontendCallbackAction },
});

export const updateHeight = (height: number) => ({
  type: 'VD/WEBSTORE/UPDATE_HEIGHT' as const,
  payload: { height },
});

export const VISUAL_DESIGNER_LOADED = 'VD/LOADED' as const;
export const visualDesignerLoaded = (mode: VisualDesignerMode) => ({
  type: VISUAL_DESIGNER_LOADED,
  payload: { mode },
});

export const visualDesignerReady = () => ({
  type: 'VD/WEBSTORE/READY',
});

export const VISUAL_DESIGNER_LANGUAGE_CHANGED = 'VD/LANGUAGE_CHANGED' as const;
export const languageChanged = (languageId: number) => ({
  type: VISUAL_DESIGNER_LANGUAGE_CHANGED,
  payload: languageId,
});

export const VISUAL_DESIGNER_RESOURCE_TEXTS_RECEIVED = 'VD/RESOURCE_TEXTS_RECEIVED' as const;
export const receiveVisualDesignerResourceTexts = (texts: Record<string, string>) => ({
  type: VISUAL_DESIGNER_RESOURCE_TEXTS_RECEIVED,
  payload: { texts },
});

export const VISUAL_DESIGNER_SET_CUSTOMER_SEGMENT_TITLES = 'VD/SET_CUSTOMER_SEGMENT_TITLES' as const;
export const setCustomerSegmentTitles = (titles: Record<string, string>) => ({
  type: VISUAL_DESIGNER_SET_CUSTOMER_SEGMENT_TITLES,
  payload: { titles },
});

export const VISUAL_DESIGNER_SET_ERRORS = 'VD/SET_ERRORS' as const;
export const setErrors = (errors: Errors) => ({
  type: VISUAL_DESIGNER_SET_ERRORS,
  payload: { errors },
});

export const VISUAL_DESIGNER_SET_SETTINGS = 'VD/SET_SETTINGS' as const;
export const setVisualDesignerSettings = (settings: Record<string, any>) => ({
  type: VISUAL_DESIGNER_SET_SETTINGS,
  payload: { settings },
});

export const VISUAL_DESIGNER_SET_VIEWDATA = 'VD/SET_VIEWDATA' as const;
export const setVisualDesignerViewData = (viewData: ViewData) => ({
  type: VISUAL_DESIGNER_SET_VIEWDATA,
  payload: { viewData },
});

export const VISUAL_DESIGNER_SET_CONTENT_BLOCKS_INFO = 'VD/SET_CONTENT_BLOCKS_INFO' as const;
export const setContentBlocksInfo = (contentBlocksInfo: ContentBlocksInfo) => ({
  type: VISUAL_DESIGNER_SET_CONTENT_BLOCKS_INFO,
  payload: { contentBlocksInfo },
});

export type GeneralAction = ReturnType<
  | typeof visualDesignerLoaded
  | typeof receiveVisualDesignerResourceTexts
  | typeof setCustomerSegmentTitles
  | typeof setErrors
  | typeof setVisualDesignerSettings
  | typeof setVisualDesignerViewData
  | typeof setContentBlocksInfo
>;
