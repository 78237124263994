import createRenderer from './createProfileRenderer';
import { routesBuilder, RouteName } from 'routes';
import { PageComponentNames } from 'behavior/pages';
import editProfileRenderer from './editProfile';
import { subAccountRenderer } from './subAccounts';
/*import { orderTemplatesRenderer, createOrderTemplateRenderer, editOrderTemplateRenderer } from './orderTemplates';*/
import orderTemplatesRenderer from './orderTemplates';
import editOrderTemplateRenderer from './orderTemplates/editOrderTemplate';
import documentRenderer from './document';
import docBasedReturnOrderRenderer from './createDocBasedReturnOrder';

export default {
  [PageComponentNames.EditProfile]: createRenderer('YourDetails', editProfileRenderer, { linkKey: 'edit' }),
  [PageComponentNames.SubAccount]: createRenderer(null, subAccountRenderer, {
    linkKey: 'subAccounts',
    backTo: {
      route: routesBuilder.forSubAccounts(),
      texts: ['ButtonText_BackToSubAccounts', 'ManageSubAccounts'],
    },
  }),
  [PageComponentNames.OrderTemplates]: createRenderer('OrderTemplates_Header', orderTemplatesRenderer, { linkKey: 'templates' }),
  [PageComponentNames.Order]: createRenderer(null, documentRenderer, {
    linkKey: 'orders',
    backTo: {
      route: routesBuilder.forOrders(),
      texts: ['ButtonText_BackToOrders', 'MyOrders'],
      supportedRoutes: new Set([RouteName.MyAccount, RouteName.Orders, RouteName.OrderAuthorizations]),
    },
  }),
  [PageComponentNames.Quote]: createRenderer(null, documentRenderer, {
    linkKey: 'quotes',
    backTo: {
      route: routesBuilder.forQuotes(),
      texts: ['ButtonText_BackToQuotes', 'MyQuotes'],
      supportedRoutes: new Set([RouteName.Quotes]),
    },
  }),
  [PageComponentNames.Invoice]: createRenderer(null, documentRenderer, {
    linkKey: 'invoices',
    backTo: {
      route: routesBuilder.forInvoices(),
      texts: ['ButtonText_BackToInvoices', 'MyInvoices'],
      supportedRoutes: new Set([RouteName.Invoices]),
    },
  }),
  [PageComponentNames.ReturnOrder]: createRenderer(null, documentRenderer, {
    linkKey: 'returnOrders',
    backTo: {
      route: routesBuilder.forReturnOrders(),
      texts: ['ButtonText_BackToReturnOrders', 'MyReturnOrders'],
      supportedRoutes: new Set([RouteName.ReturnOrders]),
    },
  }),
  [PageComponentNames.CreditNote]: createRenderer(null, documentRenderer, {
    linkKey: 'creditNotes',
    backTo: {
      route: routesBuilder.forCreditNotes(),
      texts: ['ButtonText_BackToCreditNotes', 'MyCreditNotes'],
      supportedRoutes: new Set([RouteName.CreditNotes]),
    },
  }),
  [PageComponentNames.ReturnReceipt]: createRenderer(null, documentRenderer, {
    linkKey: 'returnReceipts',
    backTo: {
      route: routesBuilder.forReturnReceipts(),
      texts: ['ButtonText_BackToReturnReceipts', 'MyReturnReceipts'],
      supportedRoutes: new Set([RouteName.ReturnReceipts]),
    },
  }),
  [PageComponentNames.Shipment]: createRenderer(null, documentRenderer, {
    linkKey: 'shipments',
    backTo: {
      route: routesBuilder.forShipments(),
      texts: ['ButtonText_BackToShipments', 'MyShipments'],
      supportedRoutes: new Set([RouteName.Shipments]),
    },
  }),
  //[PageComponentNames.CreateDocBasedReturnOrder]: createRenderer('', docBasedReturnOrderRenderer, { linkKey: 'returnOrders' }),
  //[PageComponentNames.CreateOrderTemplate]: createRenderer('', createOrderTemplateRenderer, {
  //  linkKey: 'orderTemplate',
  //  backTo: {
  //    route: routesBuilder.forOrderTemplates(),
  //    texts: ['ButtonText_BackToOrderTemplates', 'MyOrderTemplates'],
  //  },
  //}),
  //Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
  [PageComponentNames.EditOrderTemplate]: createRenderer('', editOrderTemplateRenderer, {
    linkKey: 'orderTemplate',
    backTo: {
      route: routesBuilder.forOrderTemplates(),
      texts: ['ButtonText_BackToOrderTemplates', 'MyOrderTemplates'],
    },
  }),
};
