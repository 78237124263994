import type { HeaderState } from './types';
import type { HeaderAction } from './actions';
import { HEADER_LOADED } from './actions';
import { parseContent } from 'behavior/content';

export const initialState: HeaderState = {
  desktop: null,
  desktopSticky: null,
  showDesktopSticky: false,
  tablet: null,
  tabletSticky: null,
  showTabletSticky: false,
  mobile: null,
  mobileSticky: null,
  showMobileSticky: false,
  loaded: false,
};

export default (state: HeaderState = initialState, action: HeaderAction): HeaderState => {
  switch (action.type) {
    case HEADER_LOADED:
      if (!action.payload)
        return { ...state, loaded: true };

      const { 
        desktop, desktopSticky, showDesktopSticky,
        tablet, tabletSticky, showTabletSticky,
        mobile, mobileSticky, showMobileSticky,
      } = action.payload;

      return {
        desktop: desktop ? parseContent(desktop) : null,
        desktopSticky: desktopSticky ? parseContent(desktopSticky) : null,
        showDesktopSticky,
        tablet: tablet ? parseContent(tablet) : null,
        tabletSticky: tabletSticky ? parseContent(tabletSticky) : null,
        showTabletSticky,
        mobile: mobile ? parseContent(mobile) : null,
        mobileSticky: mobileSticky ? parseContent(mobileSticky) : null,
        showMobileSticky,
        loaded: true,
      };
    default:
      return state;
  }
};
