import type { AppState } from 'behavior';
import type { BasketModel, QuickOrderBasketModel, EmptyModel, Model, ReceivedBasket, ReceivedSummary, SummaryModel, EndCustomerBasketModel } from './types';
import { addPageParamToUrl } from 'behavior/pages/helpers';
import { routesBuilder } from 'routes';
import { redirectTo } from 'behavior/routing';
import { CustomerType } from '../user';

export function getCorrectPageIndex(pageIndex: number | undefined, pageSize: number, totalCount: number) {
  if (!pageIndex || pageIndex < 0)
    return 0;

  if (totalCount <= pageIndex * pageSize)
    return Math.floor((totalCount - 1) / pageSize);

  return pageIndex;
}

export function redirectToPage(pageUrl: string, pageIndex: number, linesOnly: boolean) {
  return redirectTo(addPageParamToUrl(pageUrl, pageIndex + 1), 302, routesBuilder.forBasket(pageIndex, linesOnly));
}

export function canShowPrepayment(state: AppState) {
  return state.user?.isAuthenticated && state.user.customerType !== CustomerType.B2C;
}

export function isBasketModel(model: Model): model is BasketModel {
  return 'page' in model;
}

//Ticket 171387: Cambridge Isotope 3.9. End customer
export function isEndCustomerBasketModel(model: Model): model is EndCustomerBasketModel {
  return 'page' in model;
}

export function isQuickOrderModel(model: Model): model is QuickOrderBasketModel {
  return !('id' in model);
}

export function isEmptyModel(model: Model): model is EmptyModel {
  return !!(model as EmptyModel).cleared;
}

export function isReceivedBasket(basket: ReceivedBasket | Model): basket is ReceivedBasket {
  return 'roundOff' in basket;
}

export function isSummaryModel(basket: ReceivedSummary | SummaryModel): basket is SummaryModel {
  return 'loaded' in basket;
}
