import PropTypes from 'prop-types';
import { SearchContainerContext } from './SearchContainerContext';

const SuggestionsContainer = ({ containerProps, children }) => (
  <SearchContainerContext.Consumer>
    {({ maxHeight }) => (
      <div className="search-content-wrapper">
        <div {...containerProps} style={maxHeight ? { maxHeight } : null}>
          <div className="search-content">
          {children}
          </div>
          
        </div>
      </div>
      
    )}
  </SearchContainerContext.Consumer>
);

SuggestionsContainer.propTypes = {
  containerProps: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default SuggestionsContainer;
