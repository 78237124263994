import { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { renderRows, extendDefaultSettings } from 'components/containers';
import UseStickyHeader from './UseStickyHeader';
import { mobile as defaultContent } from './defaultContent';

const MobileHeaderTemplate = ({ isSticky }) => {
  const { mobile, showMobileSticky, mobileSticky } = useSelector(state => state.header);
  const showSticky = showMobileSticky && isSticky;

  if (!showMobileSticky)
    return renderRows(mobile ?? extendDefaultSettings(defaultContent));
  
  return (
    <UseStickyHeader showSticky={showSticky}>
      {showSticky
        ? renderRows(mobileSticky ?? extendDefaultSettings(defaultContent))
        : renderRows(mobile ?? extendDefaultSettings(defaultContent))
      }
    </UseStickyHeader>
  );
};

MobileHeaderTemplate.propTypes = {
  isSticky: PropTypes.bool,
};

export default memo(MobileHeaderTemplate);
