import styles from './Basket.module.scss';
import { memo } from 'react';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { BasketTypes } from 'behavior/basket/constants';

/*171082 - 3.5.Two shopping carts(orders and quotes)*/
const BasketTitle = ({ model }) => {

  if (model == null)
    return null;

  return (
    <>
      <h1 className={styles.title}>
        {model.basketType === BasketTypes.BASKET && (
          <SimpleText textKey="ShoppingBasket_Header" placeholder={<Placeholder className={styles.titlePlaceholder} />} />
        )}
        {model.basketType === BasketTypes.QUOTEBASKET && (
          <SimpleText textKey="ShoppingQuoteBasket_Header" placeholder={<Placeholder className={styles.titlePlaceholder} />} />
        )}
      </h1>
    </>
  );
};
export default memo(BasketTitle);