import { CASEARCH_RECEIVED, ReceivedAction, CADOWNLOAD_RECEIVED, SDSSEARCH_RECEIVED, CADOWNLOAD_CLEAR } from "./action";
import type { State } from './types';

export const initialState: State = {
    CAAttachments: [],
    SDSAttachments: [],
    FileData: '',
};
  
  export default function caSearchReceived(state: State = initialState , action: ReceivedAction) {
    if(action.type === CASEARCH_RECEIVED) {
        return {
            ...state,
            CAAttachments: action.payload.cAAttachmentName
          };
    }

    if(action.type === CADOWNLOAD_RECEIVED) {
        return {
            ...state,
            FileData: action.payload.fileData
          };
    }

    if(action.type === SDSSEARCH_RECEIVED) {
        return {
            ...state,
            SDSAttachments: action.payload.sdsAttachmentName
          };
    }

    if(action.type === CADOWNLOAD_CLEAR) {
      return {
            ...state,
            FileData: ""
          };
    }
    return state;
  }