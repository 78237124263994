//Ticket 171069: Cambridge Isotope 3.3. Redesign – product group page
import type { Product, ProductReview } from './types';
import { ProductGroupAction, ReviewProcessedAction, GroupReviewsReceivedAction, REVIEWS_PROCESSED, GROUP_REVIEWS_RECEIVED } from './actions';
import { createReducer } from 'utils/redux';
import { PRODUCTGROUP_PRODUCTS_UPDATED, productsUpdated } from './actions';

type ProductGroupPageState = {
  id: string | null;
  title: string | null;
  products: Array<Product> | null;
  productId: string | null;
  saved?: number | null;
  list: ProductReview[] | null;
  reviews?: {
    avg: number;
    total: number;
    list: ProductReview[];
    saved?: number | null;
  } | null;
};

const initialState: ProductGroupPageState = {
  id: null,
  title: null,
  products: [],
  productId: null,
  saved: null,
  list: null,
};

export default createReducer<ProductGroupPageState, ProductGroupAction>(initialState, {
    [PRODUCTGROUP_PRODUCTS_UPDATED]: calculatedFieldsLoaded,
    [REVIEWS_PROCESSED]: onReviewProcessed,
    [GROUP_REVIEWS_RECEIVED]: onGroupReviewsReceived,
});

function calculatedFieldsLoaded(state: ProductGroupPageState, action: ReturnType<typeof productsUpdated>) {
  if (!state.products || !action.payload)
    return state;

  const calculatedProducts = action.payload;

  const updatedProducts = state.products.map(product => {
    const calculatedInfo = calculatedProducts.find(p => p.id === product.id);
    //2.4 Search – Content search results - #183781
    const calculatedInfoChanged = typeof product.price === 'undefined';
    if (!calculatedInfo && product.isContent !== 'True')
        return { ...product, calculatedInfoChanged };
    return { ...product, ...calculatedInfo, calculated: true, calculatedInfoChanged };
  });

  return {
    ...state,
    products: updatedProducts,
  };
}

function onReviewProcessed(state: ProductGroupPageState, action: ReviewProcessedAction): ProductGroupPageState {
    if (!state.products || !action.payload)
        return state;
    if (state.products[0].reviews) {
        state.products[0].reviews.saved = action.payload ? Date.now() : null;
    }
    return {
        ...state,
        saved: action.payload ? Date.now() : null,
    };
}

function onGroupReviewsReceived(state: ProductGroupPageState, action: GroupReviewsReceivedAction): ProductGroupPageState {
    if (!state.products || !action.payload || !state.products[0].reviews)
        return state;
    const reviewList = state.reviews!.list;
    for (let i = 0; i < action.payload.length; i++) {
        state.reviews?.list.push(action.payload[i]);
    }
    const id = state.id;
    return {
        ...state,
        productId: id,
        list: reviewList.concat(action.payload),
    };
}
