import type { ViewContentPageValues, ViewVisualDesignerValues } from './types';

export const VISUAL_DESIGNER_UPDATE_VIEW_PAGE = 'VD/UPDATE_PAGE' as const;
export const updatePage = (page: ViewContentPageValues | ViewVisualDesignerValues) => ({
  type: VISUAL_DESIGNER_UPDATE_VIEW_PAGE,
  payload: { page },
});

export const VISUAL_DESIGNER_UPDATE_TITLE = 'VD/UPDATE_TITLE' as const;
export const updateTitle = (defaultTitle: string, translationTitle: string) => ({
  type: VISUAL_DESIGNER_UPDATE_TITLE,
  payload: {
    defaultTitle,
    translationTitle,
  },
});

export const VISUAL_DESIGNER_PREVIEW_ADD_ROW = 'APP/VISUAL_DESIGNER/PREVIEW_ADD_ROW' as const;
export const previewAddRow = (index: number, id: string) => ({
  type: VISUAL_DESIGNER_PREVIEW_ADD_ROW,
  payload: { index, id },
});

export const VISUAL_DESIGNER_PREVIEW_DUPLICATE_ROW = 'APP/VISUAL_DESIGNER/PREVIEW_DUPLICATE_ROW' as const;
export const previewDuplicateRow = (index: number, id: string) => ({
  type: VISUAL_DESIGNER_PREVIEW_DUPLICATE_ROW,
  payload: { index, id },
});

export const VISUAL_DESIGNER_PREVIEW_DELETE_ROW = 'APP/VISUAL_DESIGNER/PREVIEW_DELETE_ROW' as const;
export const previewDeleteRow = (index: number) => ({
  type: VISUAL_DESIGNER_PREVIEW_DELETE_ROW,
  payload: { index },
});

export const VISUAL_DESIGNER_PREVIEW_MOVE_CONTENT_ELEMENT = 'APP/VISUAL_DESIGNER/PREVIEW_MOVE_CONTENT_ELEMENT' as const;
export const previewMoveContentElement = (elementData: { indexBefore: number; indexAfter: number } | { indexBefore: number; indexAfter: number; sourceElementId: string; targetElementId: string }) => ({
  type: VISUAL_DESIGNER_PREVIEW_MOVE_CONTENT_ELEMENT,
  payload: elementData,
});

export type VisualDesignerPageAction = ReturnType<
  | typeof updatePage
  | typeof updateTitle
  | typeof previewAddRow
  | typeof previewDuplicateRow
  | typeof previewAddRow
  | typeof previewDeleteRow
  | typeof previewMoveContentElement
>;
