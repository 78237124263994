import type { Product, ProductListOptions, ProductListParams, SortField, ProductListPageBase, Facet, ListPreset } from './types';
import type { SystemPageData } from '../system';
import type { LoadedSettings } from 'behavior/settings';
import type { Handler } from '../types';
import type { RowContentElement, RowContentElementData } from 'behavior/content';
import type { ProductSegment } from './queries.types';
import { loadProductListPageQuery } from './queries';
import { map, first, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { parseContent } from 'behavior/content';
import { ProductSpecificationFilter } from 'behavior/products/product';
import { sortOptionsAreEqual, createLoadOptions } from './helpers';
import { requestProductsGeneralInfo } from 'behavior/pages/productList/actions';
import { PageComponentNames } from '../componentNames';
import { RouteName } from 'routes';
import { PagingType, ViewMode } from './constants';

const handler: Handler<ProductListRouteData, ProductListPage> = ({ params, options }, state$, { api, scope }) => {
  const { id } = params;
  if (!id)
    return of(null);

  const handle = (settings: LoadedSettings) => {
    const viewMode = params.viewMode;
    const loadOptions = createLoadOptions(params, { ...options, viewMode }, settings.productList);

    const state = state$.value;
    if (options && options.productsOnly) {
      return of({
        action$: of(requestProductsGeneralInfo(id, loadOptions, options.appendProducts)),
        page: state.page as ProductListPage,
      });
    }

    return api.graphApi<LoadProductListPageResponse>(loadProductListPageQuery({
      isInsiteEditor: state.insiteEditor.initialized,
      isProductGroupingEnabled: settings.product.productGrouping.isEnabled,
    }), {
      id,
      options: loadOptions,
      specificationFilter: ProductSpecificationFilter.ForList,
      loadLargeImages: scope === 'SERVER',
      loadCategories: state.analytics && state.analytics.isTrackingEnabled,
    }).pipe(
      map(result => {
        const page = parsePageContent(result, params, settings.lastViewedEnabled);
        if (!page || !page.facets)
          return null;

        page.products.forEach(p => {
          p.defaultUom = p.uom;
        });

        return { page };
      }),
    );
  };

  return state$.pipe(
    first(({ settings, analytics }) => settings.loaded && !!analytics),
    switchMap(({ settings }) => handle(settings as LoadedSettings)),
  );
};

export default handler;

function parsePageContent(result: LoadProductListPageResponse, params: ProductListParams, lastViewedEnabled: boolean) {
  const page = result.pages.productList;

  if (!page)
    return null;

  const { sort, viewMode, page: index, facets, id } = params;

  const selectedViewMode = viewMode || page.defaultViewMode;
  const pageFacets = page.products.facets;

  const normalizedPage: NormalizedPage = {
    ...page,
    id,
    selectedViewMode,
    lastViewedEnabled,
    component: PageComponentNames.ProductList as const,
    facets: pageFacets,
    products: page.products.products,
    totalCount: page.products.totalCount,
    headerContent: page.headerContent && parseContent(page.headerContent),
    footerContent: page.footerContent && parseContent(page.footerContent),
    selectedSorting: sort || page.defaultSorting,
    index: (!sort || sortOptionsAreEqual(sort, page.defaultSorting))
      && selectedViewMode === page.defaultViewMode
      && !index
      && checkIfFacetCrawlable(facets, pageFacets && pageFacets.facets),
  };

  return normalizedPage;
}

function checkIfFacetCrawlable(selectedFacets: Record<string, string[]> | undefined, facets: Facet[] | null) {
  if (!facets)
    return true;

  for (const facetName in selectedFacets) {
    if (!selectedFacets[facetName].length)
      continue;

    const selectedFacet = facets.find(f => f.name === facetName);
    if (selectedFacet && !selectedFacet.crawlable)
      return false;
  }

  return true;
}

type ProductListPage = {
  component: PageComponentNames.ProductList;
  products: Product[];
  totalCount: number;
  selectedSorting?: SortField | null;
};

type ProductListRouteData = SystemPageData & {
  routeName: RouteName.ProductList;
  params: ProductListParams;
  options?: ProductListOptions;
};

type NormalizedPage = ProductListPageBase & {
  component: PageComponentNames.ProductList;
  metaTitle: string;
  metaDescription: string | null;
  pageTitle: string;
  description: string | null;
  defaultSorting: SortField | null;
  sortingEnabled: boolean;
  backgroundColor: string | null;
  backgroundImage: string | null;
  headerContent: RowContentElement[] | null;
  footerContent: RowContentElement[] | null;
  index: boolean;
};

type LoadProductListPageResponse = {
  pages: {
    productList: {
      metaTitle: string;
      metaDescription: string | null;
      pageTitle: string;
      description: string | null;
      preset: ListPreset | null;
      products: {
        products: Array<ProductSegment & {
          variantComponentGroups: Array<{
            id: string;
          }>;
        }>;
        facets: {
          facets: Array<{
            name: string;
            title: string;
            sortDescending: boolean;
            crawlable: boolean;
            values: Array<{
              title: string;
              textTitle: string | null;
              count: number;
              value: string | null;
              selected: boolean;
            }>;
          }>;
          multiSelect: boolean;
        } | null;
        totalCount: number;
      };
      defaultSorting: {
        field: string;
        ascending: boolean;
      } | null;
      sortingEnabled: boolean;
      defaultViewMode: ViewMode;
      viewModeSwitchEnabled: boolean;
      showThumbnails: boolean;
      pagingType: PagingType;
      backgroundColor: string | null;
      backgroundImage: string | null;
      headerContent: RowContentElementData[] | null;
      footerContent: RowContentElementData[] | null;
    } | null;
  };
};
