export const enum UpdateType {
  Added = 'Added',
  Removed = 'Removed',
}

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
export const enum OrderTemplateType {
    CreateTemplate = 'CreateTemplate',
    EditTemplate = 'EditTemplate',
    AddToBasketTemplate = 'AddToBasketTemplate',
}
