import type {
  CalculatedProduct,
  CalculatedVariantsProduct,
  ProductListLoadOptions,
  Paging,
  Product,
  ProductVariants,
  SortField,
} from './types';

export const PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED = 'PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED' as const;
export const requestProductsGeneralInfo = (listPageId: string, options: ProductListLoadOptions, appendProducts = false) => ({
  type: PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED,
  payload: { listPageId, options, appendProducts },
});
export type RequestProductsGeneralInfoAction = ReturnType<typeof requestProductsGeneralInfo>;

export const PRODUCTLIST_PRODUCTS_GENERALINFO_LOADED = 'PRODUCTLIST_PRODUCTS_GENERALINFO_LOADED' as const;
export const productsGeneralInfoLoaded = (
  products: Product[],
  appendProducts: boolean,
  pageSize: number,
  totalCount?: number,
  sorting?: SortField,
) => ({
  type: PRODUCTLIST_PRODUCTS_GENERALINFO_LOADED,
  payload: {
    products,
    appendProducts,
    pageSize,
    totalCount,
    sorting,
  },
});
export type ProductsGeneralInfoLoadedAction = ReturnType<typeof productsGeneralInfoLoaded>;

export const PRODUCTLIST_CALCULATED_FIELDS_REQUESTED = 'PRODUCTLIST_CALCULATED_FIELDS_REQUESTED' as const;
export const requestCalculatedFields = (options: CalculatedFieldsLoadOptions, variantsOnly = false) => ({
  type: PRODUCTLIST_CALCULATED_FIELDS_REQUESTED,
  payload: { options, variantsOnly },
});
type RequestCalculatedFieldsAction = ReturnType<typeof requestCalculatedFields>;

export const PRODUCTLIST_PRODUCTS_UPDATED = 'PRODUCTLIST_PRODUCTS_UPDATED' as const;
export const productsUpdated = (products: CalculatedProduct[] | CalculatedVariantsProduct[] | ProductVariants[]) => ({
  type: PRODUCTLIST_PRODUCTS_UPDATED,
  payload: products,
});
export type ProductsUpdatedAction = ReturnType<typeof productsUpdated>;

export const PRODUCTLIST_VARIANTS_GENERALINFO_REQUESTED = 'PRODUCTLIST_VARIANTS_GENERALINFO_REQUESTED' as const;
export const requestVariantsGeneralInfo = (options: VariantsInfoLoadOptions) => ({
  type: PRODUCTLIST_VARIANTS_GENERALINFO_REQUESTED,
  payload: { options },
});
type RequestVariantsGeneralInfo = ReturnType<typeof requestVariantsGeneralInfo>;

export type ProductListPageAction =
    | RequestProductsGeneralInfoAction
    | ProductsGeneralInfoLoadedAction
    | RequestCalculatedFieldsAction
    | ProductsUpdatedAction
    | RequestVariantsGeneralInfo;


type VariantsInfoLoadOptions = {
  ids: string[];
  uomId: string | null;
};

type CalculatedFieldsLoadOptions = {
  ids: string[];
  uomId: string | null;
  page?: Paging;
};
