import PropTypes from 'prop-types';
import styles from './Search.module.scss';
import { SimpleText } from 'components/sanaText';
import { useEffect, useRef } from 'react';
//Ticket 183782: Cambridge Isotope : Phase 2- 2.5. Search – Special characters
const InsertSymbolBlock = props => {
  const {
    onChangeSymbol,
    onClickOutside,
    formRef,
  } = props;
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      const symbol = document.getElementById('symbol');
      if (ref.current && !formRef.current[0].contains(event.target)) {
        if (symbol.id !== event.target.id)
          onClickOutside(false);
      }
    };
    
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  if (!props.showSymbolBar)
    return null;

  return (
    <div ref={ref}>
    <div className={`${styles.insertSymbolBlock}`}>
      <span className={`${styles.insertSymbolText}`}><SimpleText textKey="SearchInsertSymbol" /></span>
      <button id="symbol" type="button" title={'±'} value="±" onClick={onChangeSymbol}>{'±'}</button>
      <button id="symbol" type="button" title={'α'} value="α" onClick={onChangeSymbol}>{'α'}</button>
      <button id="symbol" type="button" title={'β'} value="β" onClick={onChangeSymbol}>{'β'}</button>
      <button id="symbol" type="button" title={'γ'} value="γ" onClick={onChangeSymbol}>{'γ'}</button>
      <button id="symbol" type="button" title={'δ'} value="δ" onClick={onChangeSymbol}>{'δ'}</button>
      <button id="symbol" type="button" title={'Δ'} value="Δ" onClick={onChangeSymbol}>{'Δ'}</button>
      <button id="symbol" type="button" title={'ε'} value="ε" onClick={onChangeSymbol}>{'ε'}</button>
      <button id="symbol" type="button" title={'ζ'} value="ζ" onClick={onChangeSymbol}>{'ζ'}</button>
      <button id="symbol" type="button" title={'κ'} value="κ" onClick={onChangeSymbol}>{'κ'}</button>
      <button id="symbol" type="button" title={'⍵'} value="⍵" onClick={onChangeSymbol}>{'⍵'}</button>
      <button id="symbol" type="button" title={'⍥'} value="⍥" onClick={onChangeSymbol}>{'⍥'}</button>
      <button id="symbol" type="button" title={'μ'} value="μ" onClick={onChangeSymbol}>{'μ'}</button>
      </div>
      </div>
  );
};

InsertSymbolBlock.propTypes = {
  onChangeSymbol: PropTypes.func.isRequired,
  showSymbolBar: PropTypes.bool,
  onClickOutside: PropTypes.func,
};
export default InsertSymbolBlock;