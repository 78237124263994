/*171054 - CIL(Phase 1)  3.1.Contacts � registration process update -System page*/
import loadable from '@loadable/component';

const RegistrationConfirmation = loadable(() => import('./RegistrationConfirmation'));

export function registrationConfirmationRenderer(_page) {
    return <RegistrationConfirmation />;
}


