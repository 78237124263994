//CIL 2.7.SDS Search
import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';

export default (_routeData, _state$, _) => {
  return of({
    page: {
      component: PageComponentNames.SDSSearch,
    },
  });
};
