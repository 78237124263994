import type { Epic } from 'behavior/types';
import { exhaustMap, map, pluck } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CADOWNLOAD_REQUESTED, CASearchDownloadRequestedAction, caDownloadReceived } from './action';
import { caDownloadQuery } from './queries';

  const epic: Epic<CASearchDownloadRequestedAction> = (action$, _, { api }) => action$.pipe(
      ofType(CADOWNLOAD_REQUESTED),
      pluck('payload'),
      exhaustMap(({ fileName, batchNo, itemNo }) => api.graphApi(caDownloadQuery, { fileName, batchNo, itemNo }).pipe(
       map(caDownloadReceived),
      )),
    );
  
  export default epic;

