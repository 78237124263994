import styles from './Summary.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { routesBuilder } from 'routes';
import { AbilityState } from 'behavior/user/constants';
import { abilityPropType } from 'behavior/user';
import { useRoutesChecker } from 'components/primitives/checkers';
import { RouteName } from 'routes';
import { DocumentType } from 'behavior/documents';
import FooterLink from './FooterLink';
import { useDispatch } from 'react-redux';
import { Button } from 'components/primitives/buttons';
import { changeBasketType, changeBasketTypeRedirectToCheckOut } from 'behavior/basket';
import { SimpleText } from 'components/sanaText';
import { LinkButton } from 'components/primitives/links';
import { BasketTypes } from './constants';

const checkoutRoutes = [RouteName.Checkout];

//171082 - 3.5.Two shopping carts(orders and quotes)
const Footer = ({
  editDocumentType,
  createOrderAbility,
  isBlocked,
  createQuoteAbility,
  salesAgreementId,
  options,
  isQuote,/*171082 - 3.5.Two shopping carts(orders and quotes)*/
  basketType,
}) => {

  const dispatch = useDispatch();

  const canSubmitOrder = createOrderAbility !== AbilityState.NotAvailable;
  const canCreateQuote = createQuoteAbility !== AbilityState.NotAvailable;
  const isCheckoutRoute = useRoutesChecker(checkoutRoutes);
  const viewBasketText = (basketType === BasketTypes.basket) ? "ViewBasket" : "ViewQuoteBasket";
  const basketLink = <FooterLink style={options.footerBasketLinkStyle} textKey={viewBasketText} basketType={basketType} isShoppingCart={ true} />;

  if (isCheckoutRoute)
    return <div className={styles.panelFooter}>{basketLink}</div>;

  const isEditQuote = editDocumentType === DocumentType.Quote,
    isEditOrder = editDocumentType === DocumentType.Order;

  /*171082 - 3.5.Two shopping carts(orders and quotes)*/
  const checkoutLink = (isEditOrder || canSubmitOrder && !isEditQuote) && !isQuote
    ? (
      <FooterLink
        style={options.footerCheckoutLinkStyle}
        textKey="ButtonText_Checkout"
        basketType={basketType}
        isShoppingCart={false}
        disabled={isBlocked}
      />
    )
    : (isEditQuote || canCreateQuote) && !salesAgreementId && isQuote ? (
      <FooterLink style={options.footerCheckoutLinkStyle} textKey="ButtonText_GetQuote" basketType={basketType} isShoppingCart={false} />
    ) : null;

  return (
    <div className={styles.panelFooter}>
      {options.swapFooterLinksPosition
        ? <>{checkoutLink}{basketLink}</>
        : <>{basketLink}{checkoutLink}</>
      }
    </div>
  );
};

Footer.propTypes = {
  editDocumentType: PropTypes.string,
  createOrderAbility: abilityPropType.isRequired,
  isBlocked: PropTypes.bool,
  createQuoteAbility: abilityPropType.isRequired,
  salesAgreementId: PropTypes.string,
  options: PropTypes.shape({
    footerBasketLinkStyle: PropTypes.number.isRequired,
    footerCheckoutLinkStyle: PropTypes.number.isRequired,
    swapFooterLinksPosition: PropTypes.bool.isRequired,
  }).isRequired,
  isQuote: PropTypes.bool,/*171082 - 3.5.Two shopping carts(orders and quotes)*/
  basketType: PropTypes.string,
};

export default memo(Footer);
