/*171082 - 3.5.Two shopping carts(orders and quotes)*/
import styles from './Summary.module.scss';
import { memo, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { useLayoutShifter, useExtras } from 'utils/layout';
import { CartSummaryIcon } from 'components/primitives/icons';
import { getThemeFontSizeClassName } from 'components/theme';
import { BasketLinkLayoutOption, BasketTypes } from './constants';
import { Button } from 'components/primitives/buttons';
import { useDispatch } from 'react-redux';
import { changeBasketType } from 'behavior/basket';

const HeaderQuote = ({ totalCount, showFixed, options, style }) => {
  const extras = useExtras();
  const ref = useRef();
  const { topFixedElementsHeight } = useLayoutShifter();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!showFixed)
      return;

    // Updates basket link top style property depending on layout top shift value.
    ref.current.style.top = `${topFixedElementsHeight}px`;
  }, [showFixed, topFixedElementsHeight]);

  let iconWithTextQuote;
  switch (options.basketLinkLayout) {
    case BasketLinkLayoutOption.TextLeftOfIcon:
      iconWithTextQuote = (
        <>
          <span><SimpleText textKey="Quote" /> </span>
          <span className={styles.summaryNumber}>{totalCount}</span>
          {options.showBasketLinkLabel && <span> <SimpleText textKey="ShoppingBasket_QuantityPostfix" /></span>}
          <CartSummaryIcon className={joinClasses(styles.icon, styles.textLeft)} aria-hidden />
        </>
      );
      break;
    case BasketLinkLayoutOption.TextBelowOfIcon:
      iconWithTextQuote = (
        <>
          <CartSummaryIcon className={joinClasses(styles.icon, styles.noMargin)} aria-hidden />
          <span><SimpleText textKey="Quote" /> </span>
          <span className={styles.textBelowIcon}>
            <span className={styles.summaryNumber}>{totalCount}</span>
            {options.showBasketLinkLabel && <span> <SimpleText textKey="ShoppingBasket_QuantityPostfix" /></span>}
          </span>
        </>
      );
      break;
    case BasketLinkLayoutOption.ShowNumberOfProductsAsBadge:
      iconWithTextQuote = (
        <span className={styles.badgeContainer}>
          <CartSummaryIcon className={joinClasses(styles.icon, styles.noMargin)} aria-hidden />
          <div className="basket-summary-header-wrap">
            <span><SimpleText textKey="Quote" /> </span>
            {!!totalCount && <span className={`${styles.summaryNumber} ${styles.badge}`}>{totalCount}</span>}
          </div>
          
        </span>
      );
      break;
    default:
      iconWithTextQuote = (
        <>
          <CartSummaryIcon className={styles.icon} aria-hidden />
          <div className="basket-summary-header-wrap">
            <span><SimpleText textKey="Quote" /> </span>
            <span className={styles.summaryNumberWrapper}>
              <span className={styles.summaryNumber}>{totalCount}</span>
              {options.showBasketLinkLabel && <span> <SimpleText textKey="ShoppingBasket_QuantityPostfix" /></span>}
            </span>
          </div>
          
        </>
      );
      break;
  }

  const basketLink = (
      <Button
        className={
          joinClasses(
            styles.header,
            styles.linkButton,
            !totalCount && styles.isEmpty,
            !options.showBasketLinkLabel && styles.withoutLabel,
            showFixed && styles.fixed,
            getThemeFontSizeClassName(options.basketLinkThemeFontSize),
          )
        }
        onClick={() => dispatch(changeBasketType(BasketTypes.quotebasket))}
        ref={ref}
        style={showFixed && extras ? style : null}
      >
      {iconWithTextQuote}
      </Button>
  );

  return (
    <div style={style}>
      {showFixed && extras ? ReactDOM.createPortal(basketLink, extras) : basketLink}
      <span
        className={joinClasses(
          styles.placeholder,
          showFixed && styles.fixed,
          getThemeFontSizeClassName(options.basketLinkThemeFontSize),
        )}
        aria-hidden
      >
        <CartSummaryIcon className={styles.icon} aria-hidden />
        <span className={styles.summaryNumber}>{totalCount}</span>
      </span>
    </div>
  );
};

HeaderQuote.propTypes = {
  totalCount: PropTypes.number,
  showFixed: PropTypes.bool,
  options: PropTypes.shape({
    basketLinkLayout: PropTypes.number.isRequired,
    showBasketLinkLabel: PropTypes.bool.isRequired,
    basketLinkThemeFontSize: PropTypes.string.isRequired,
  }).isRequired,
  style: PropTypes.object,
};

export default memo(HeaderQuote);
