//171054 - CIL(Phase 1)  3.1.Contacts � registration process update -System page
import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';

export default (_routeData, _state$, _) => {
  return of({
    page: {
      component: PageComponentNames.RegistrationConfirmation,
    },
  });
};
