import type { Action } from 'redux';
import type { AppState } from 'behavior';
import type { Epic } from 'behavior/types';
import type { ModifiedLines } from 'behavior/basket/types';
import type { OrderTemplate, OrderTemplateLine, Product, SaveOrderTemplateResult } from './types';
import {
    orderTemplatesQuery,
    orderTemplateLinesQuery,
    addToBasketMutation,
    removeTemplatesMutation,
    productsQuery,
    saveOrderTemplateMutation,
    lineAddToBasketMutation,
    removeProductFromOrderTemplateMutation,
    resetQuantityMutation,
} from './queries';
import {
    ORDER_TEMPLATES_REQUESTED,
    ORDER_TEMPLATE_LINES_REQUESTED,
    ORDER_TEMPLATES_ADDING_REQUESTED,
    ORDER_TEMPLATES_REMOVAL_REQUESTED,
    ORDER_TEMPLATE_RESET_QUANTITY_REQUESTED,
    orderTemplatesReceived,
    orderTemplateLinesReceived,
    orderTemplatesAdded,
    orderTemplatesRemoved,
    OrderTemplateAction,
    ORDER_TEMPLATE_PRODUCTS_REQUESTED,
    receiveProducts,
    SaveOrderTemplateAction,
    ORDER_TEMPLATE_CREATED,
    orderTemplateReceivedErrorMessage,
    ORDER_TEMPLATE_BASKET_LINE_LIST_ADDING_REQUESTED,
    AddLinesToBasketTemplateAction,
    ORDER_TEMPLATE_DELETE_BASKET_LINE,
    DeleteBasketLine,
    resetQuantityReseted,
} from './actions';
import { createApiCallEpic } from '../helpers';
import { combineEpics, ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { of, from } from 'rxjs';
import { mergeMap, map, takeUntil, switchMap, startWith, pluck } from 'rxjs/operators';
import { concatToIfEmpty } from 'utils/rxjs';
import { basketChangeStarted, basketChangeCompleted, navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { trackAddToBasket, getProductsTrackingDataFromLines } from 'behavior/analytics';
import { LoadedSettings } from 'behavior/settings';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormName } from 'behavior/pages';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { toasts } from 'behavior/toasts';

type OrderTemplatesResponse = {
  orderTemplates: OrderTemplate[] | null;
};

const loadOrderTemplatesEpic = createApiCallEpic<OrderTemplateAction, OrderTemplatesResponse>(
  ORDER_TEMPLATES_REQUESTED,
  orderTemplatesQuery,
  orderTemplatesReceived,
);

type OrderTemplateLinesResponse = {
  orderTemplates: { lines: OrderTemplateLine[] }[] | null;
};

const loadOrderTemplateLinesEpic: Epic<OrderTemplateAction> = (action$, state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(ORDER_TEMPLATE_LINES_REQUESTED),
      mergeMap(action => api.graphApi<OrderTemplateLinesResponse>(orderTemplateLinesQuery, {
        ...action.payload,
        loadCategories: state$.value.analytics?.isTrackingEnabled,
      }).pipe(
          map(({ orderTemplates }) => 
              orderTemplateLinesReceived(action.payload.id,
                  orderTemplates &&
                  orderTemplates[0] &&
                  orderTemplates[0].lines.filter(l => l.product.exists || l.hasConfiguration),
              )),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      ),
    ),
  );
};

type AddToBasketResponse = {
  orderTemplates: {
    addToBasket: {
      templatesAmount: number;
      linesAmount: number;
      modifiedLines: ModifiedLines;
    } | null;
  } | null;
};

const addToBasketEpic: Epic<OrderTemplateAction> = (action$, state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  return action$.pipe(
    ofType(ORDER_TEMPLATES_ADDING_REQUESTED),
    switchMap(action => api.graphApi<AddToBasketResponse>(addToBasketMutation, { ...action.payload, requestModifiedLines: isTrackingEnabled(state$.value) }).pipe(
      mergeMap(({ orderTemplates }) => {
        if (!orderTemplates || !orderTemplates.addToBasket)
          return of(orderTemplatesAdded(0), basketChangeCompleted(0));

        const { templatesAmount, linesAmount, modifiedLines } = orderTemplates.addToBasket;
        const actions: Array<Action> = [orderTemplatesAdded(templatesAmount), basketChangeCompleted(linesAmount)];

        const addedProducts = modifiedLines ? getProductsTrackingDataFromLines(modifiedLines.list) : [];
        if (addedProducts && addedProducts.length) {
          actions.push(trackAddToBasket({ products: addedProducts }));
        }

        const backTo = state$.value.page.backTo;
        if (backTo) {
          actions.push(navigateTo(backTo.routeData, backTo.url));
        } else if ((state$.value.settings as LoadedSettings).basket.redirectOnAdd) {
          actions.push(navigateTo(routesBuilder.forBasket()));
        }

        return from(actions);
      }),
      retryWithToast(action$, logger),
      concatToIfEmpty(of(orderTemplatesAdded(0), basketChangeCompleted(0))),
      takeUntil(locationChanged$),
      startWith(basketChangeStarted()),
    )),
  );
};

const orderTemplatesRemoveEpic: Epic<OrderTemplateAction> = (action$, _state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(ORDER_TEMPLATES_REMOVAL_REQUESTED),
    mergeMap(action => api.graphApi<unknown>(removeTemplatesMutation, action.payload).pipe(
      map(() => orderTemplatesRemoved(action.payload.ids)),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );
};

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
const requestProductEpic: Epic<OrderTemplateAction> = (action$, _state$, { api, logger }) => {

  return action$.pipe(
    ofType(ORDER_TEMPLATE_PRODUCTS_REQUESTED),
    switchMap(
      action => api.graphApi<ProductsQueryResponse>(productsQuery, { options: { ids: action.payload.ids, loadAllProducts: action.payload.loadAllProducts } })
        .pipe(
          pluck('catalog', 'products', 'products'),
          mergeMap(products => {
            return of(receiveProducts(products), unsetLoadingIndicator());
          }),
          retryWithToast(action$, logger),
          startWith(setLoadingIndicator()),
        )),
  );
};

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
const saveOrderTemplateEpic: Epic<SaveOrderTemplateAction> = (action$, state$, dependencies) => {

  let templateForm = FormName.CreateOrderTemplate;
  return action$.pipe(
    ofType(ORDER_TEMPLATE_CREATED),
    pluck('payload'),
    switchMap(({ input }) => dependencies.api.graphApi<CreateOrderTemplateResponse>(saveOrderTemplateMutation, { input }).pipe(
        mergeMap(({ orderTemplates }) => {
          const { isEdit } = input;
        templateForm = isEdit ? FormName.EditOrderTemplate : FormName.CreateOrderTemplate;

        if (orderTemplates?.saveTemplate?.isError)
          return of(orderTemplateReceivedErrorMessage(orderTemplates?.saveTemplate), unsetLoadingIndicator(), unlockForm(templateForm));

        if (isEdit) {
            toasts.success('', { textKey: 'OrderTemplate_Updated' });
            return of(navigateTo(routesBuilder.forOrderTemplates()), unlockForm(templateForm));
        }
        else
           toasts.success('', { textKey: 'OrderTemplate_Created' });
        const basketId = orderTemplates?.saveTemplate?.basketId;
        if (basketId !== undefined)
            return of(navigateTo(routesBuilder.forEditOrderTemplate(basketId)), unlockForm(templateForm));

        return of(navigateTo(routesBuilder.forOrderTemplates()), unlockForm(templateForm));      
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(templateForm), unsetLoadingIndicator())),
      retryWithToast(action$, dependencies.logger, () => of(unlockForm(templateForm), unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );
};

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
const addLinesToBasketEpic: Epic<AddLinesToBasketTemplateAction> = (action$, state$, { api, logger }) => {

  let templateForm = FormName.CreateOrderTemplate;
  return action$.pipe(
    ofType(ORDER_TEMPLATE_BASKET_LINE_LIST_ADDING_REQUESTED),
    pluck('payload'),
    switchMap(({ input }) => api.graphApi<AddLinesToBasketResponse>(lineAddToBasketMutation, { input }).pipe(
      mergeMap(({ orderTemplates }) => {
        const { isEdit } = input;
        templateForm = isEdit ? FormName.EditOrderTemplate : FormName.CreateOrderTemplate;

         if (orderTemplates?.addLinesToBasket?.isError)
          return of(orderTemplateReceivedErrorMessage(orderTemplates?.addLinesToBasket), unsetLoadingIndicator(), unlockForm(templateForm));

          toasts.success('', { textKey: 'OrderTemplate_SavedAndAddItemsToBasket' });

        return of(navigateTo(routesBuilder.forBasket()));
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(templateForm), unsetLoadingIndicator())),
      retryWithToast(action$, logger, () => of(unlockForm(templateForm), unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );
};

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
const deleteBasketLineEpic: Epic<DeleteBasketLine> = (action$, state$, { api, logger }) => {

    return action$.pipe(
        ofType(ORDER_TEMPLATE_DELETE_BASKET_LINE),
        pluck('payload'),
        switchMap(({ line, orderTemplateId }) => api.graphApi(removeProductFromOrderTemplateMutation, { input: { line, orderTemplateId } }).pipe(
            mergeMap(() => {

                toasts.success('', { textKey: 'OrderTemplate_BasketLineSuccessfullyDeleted' });
                return of(unsetLoadingIndicator());
            }),
            catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
            retryWithToast(action$, logger, () => of(unsetLoadingIndicator())),
            startWith(setLoadingIndicator()),
        )),
    );
};

const resetQuantityEpic: Epic<OrderTemplateAction> = (action$, _state$, { api, logger }) => {
    const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

    return action$.pipe(
        ofType(ORDER_TEMPLATE_RESET_QUANTITY_REQUESTED),
        mergeMap(action => api.graphApi<unknown>(resetQuantityMutation, action.payload).pipe(
            map(() => { return resetQuantityReseted() }),
            retryWithToast(action$, logger),
            takeUntil(locationChanged$),
        )),
    );
};

export default combineEpics(
    loadOrderTemplatesEpic,
    loadOrderTemplateLinesEpic,
    addToBasketEpic,
    orderTemplatesRemoveEpic,
    requestProductEpic,
    saveOrderTemplateEpic,
    addLinesToBasketEpic,
    deleteBasketLineEpic,
    resetQuantityEpic,
);

function isTrackingEnabled(state: AppState) {
  return state.analytics && state.analytics.isTrackingEnabled;
}

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
export type ProductsQueryResponse = {
    catalog: {
        products: {
            products: Product[];
        };
    };
};

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
export type SaveOrderTemplateResponse = {
    orderTemplates: {
        save: SaveOrderTemplateResult;
    } | null;
};

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
export type CreateOrderTemplateResponse = {
    orderTemplates: {
        saveTemplate: SaveOrderTemplateResult;
    } | null;
};

//Ticket 183773: Cambridge Isotope : Phase 2- 2.2. Order templates (create, edit and share)
type AddLinesToBasketResponse = {
    orderTemplates: {
        addLinesToBasket: SaveOrderTemplateResult;
    } | null;
};
