import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import BasketTitle from './BasketTitle';
import BasketBreadcrumb from './Breadcrumb';
import BasketPageContent from './BasketPageContent';
import { connect } from 'react-redux';

/*171082 - 3.5.Two shopping carts(orders and quotes)*/
const BasketPage = ({ preset, header, footer, model }) => (
  <>
    <Container>
      <BasketBreadcrumb />{/*171082 - 3.5.Two shopping carts(orders and quotes)*/}
      <BasketTitle model={model} />{/*171082 - 3.5.Two shopping carts(orders and quotes)*/}
    </Container>
    <BasketPageContent preset={preset} header={header} footer={footer} />
  </>
);

BasketPage.propTypes = {
  preset: PropTypes.string,
  header: PropTypes.array,
  footer: PropTypes.array,
};

/*171082 - 3.5.Two shopping carts(orders and quotes)*/
export default connect(({basket: { model } }) => ({
  model: model,
}))(BasketPage);

