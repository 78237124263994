/*171082 - 3.5.Two shopping carts(orders and quotes)*/
import type {
    BasketAction, AddProductsAction, BasketHoverAction, BasketUpdatedAction, BasketReceivedAction,
    BasketSummaryReceivedAction, BasketArrivedAction, ReceiveAgreementLinesAction,
    EndCustomerAddressSelectedAction, EndCustomerAddressSubmittedAction, SendEndCustomerFormAction,
} from '../actions';
import type { ReceivedBasket, State, BasketModel, SalesAgreementLine, BasketConfigurationResultLine, EndCustomerBasketModel } from '../types';
import {
    BASKET_UPDATE,
    BASKET_UPDATED,
    BASKET_RECEIVED,
    BASKET_SUMMARY_RECEIVED,
    BASKET_ADD_PRODUCTS,
    BASKET_HOVER,/*171082 - 3.5.Two shopping carts(orders and quotes)*/
    BASKET_ARRIVED,
    BASKET_AGREEMENT_LINES_RECEIVED,
    END_CUSTOMER_ADDRESS_SELECTED,
    END_CUSTOMER_ADDRESS_SUBMITTED,
    END_CUSTOMER_FORM_SUBMIT,
} from '../actions';
import { createReducer } from 'utils/redux';
import {
    VIEWER_CHANGED,
    LANGUAGE_CHANGED,
    BASKET_CHANGE_STARTED,
    BASKET_CHANGE_COMPLETED,
    EventAction, BasketChangeCompletedAction,
} from 'behavior/events';
import { USER_ANON_EXPIRED, BroadcastUserAction } from 'behavior/user';
import { Updaters } from '../constants';
import { NAVIGATED, RoutingAction } from 'behavior/routing';
import { generateKey } from 'utils/helpers';
import {
    AGREEMENT_APPLIED,
    AGREEMENT_CANCELED,
    SalesAgreementAction, AgreementAppliedAction,
} from 'behavior/salesAgreements';
import { isBasketModel, isReceivedBasket, isEmptyModel, isEndCustomerBasketModel  } from '../helpers';

type Action = BasketAction | EventAction | SalesAgreementAction | RoutingAction | BroadcastUserAction;

export const initialState: State = {
    modifiedDate: null,
    lastModifiedLineId: null,
    updated: {
        updaterId: null,
        date: null,
        linesAmount: 0,
    },
    model: null,
    isQuickOrderMode: false,
    summary: null,
    isErrorMode: false,
};

//Ticket 171387: Cambridge Isotope 3.9. End customer
export default createReducer<State, Action>(initialState, {
    [BASKET_UPDATE]: onBasketUpdate,
    [BASKET_UPDATED]: onBasketUpdated,
    [BASKET_CHANGE_COMPLETED]: onBasketChangeCompleted,
    [BASKET_RECEIVED]: onBasketReceived,
    [BASKET_SUMMARY_RECEIVED]: onBasketSummaryReceived,
    [BASKET_CHANGE_STARTED]: onBasketChangeStarted,
    [BASKET_ADD_PRODUCTS]: onBasketUpdating,
    [BASKET_HOVER]: onBasketHover,/*171082 - 3.5.Two shopping carts(orders and quotes)*/
    [VIEWER_CHANGED]: onBasketSummaryExpired,
    [USER_ANON_EXPIRED]: onBasketSummaryExpired,
    [LANGUAGE_CHANGED]: onBasketSummaryExpired,
    [NAVIGATED]: onNavigated,
    [BASKET_ARRIVED]: onBasketArrived,
    [AGREEMENT_APPLIED]: onAgreementApplied,
    [AGREEMENT_CANCELED]: onAgreementCanceled,
    [BASKET_AGREEMENT_LINES_RECEIVED]: onAgreementLinesReceived,
    [END_CUSTOMER_ADDRESS_SELECTED]: onEndAddressSelected,
    [END_CUSTOMER_ADDRESS_SUBMITTED]: onEndAddressSubmitted,
    [END_CUSTOMER_FORM_SUBMIT]: onEndCustomerFormSubmit,
});

//Ticket 208188: [CIL] End customer address - submit should create new address in ERP AND add that address to the line.
function onEndCustomerFormSubmit(state: State, action: SendEndCustomerFormAction) {

    if (!state.model || !isEndCustomerBasketModel(state.model))
        return state;

    const result = state;
    result.summary = { ...result.summary!, endCustomerSubmitted: false };
    return result;
}

//Ticket 171387: Cambridge Isotope 3.9. End customer
function onEndAddressSubmitted(state: State, action: EndCustomerAddressSubmittedAction): State {
    if (!state.model || !isEndCustomerBasketModel(state.model))
        return state;
    const updatedEndCustomerList = getEndCustomerList(state, action.payload.addressInfo.formatted);
    const productLinesList = state.model.productLines.list;
    const productLinesLists = getBasketLineInfo(productLinesList, state, '', action.payload.basketLineId, action.payload.addressInfo.formatted);
    const modelObject = { model: updatedEndCustomerList };
    return {
        ...state,
        summary: {
            ...state.summary!,
            endCustomerSubmitted: true,
        },
    };
}

//Ticket 171387: Cambridge Isotope 3.9. End customer
function getEndCustomerList(state: State, formattedAddress: string){
    if (!state.model || !isEndCustomerBasketModel(state.model))
        return state;
    if(state.model === null){
        return state.model;
    }
    const endCustomer = { id: null, formatted: formattedAddress };
    const len = state.model.updatedEndCustomerAddresses.length;
    state.model.updatedEndCustomerAddresses.splice(len+1, 0, endCustomer);
    return state.model;
}

//Ticket 171387: Cambridge Isotope 3.9. End customer
function onEndAddressSelected(state: State, action: EndCustomerAddressSelectedAction) {
    if (!state.model || !isEndCustomerBasketModel(state.model))
        return state;
    const productLinesList = state.model.productLines.list;
    const productLinesLists = getBasketLineInfo(productLinesList, state, action.payload.id, action.payload.basketLineId, action.payload.formatted);
    const modelObject = { model: productLinesLists };
    return {
        ...state,
        modelObject,
    };
}

//Ticket 171387: Cambridge Isotope 3.9. End customer
function getBasketLineInfo(productLinesLists: EndCustomerBasketModelProductLinesList, state: State, endCustomerId: string, basketLineId: string, formated: string) {
    for (let idx = 0; idx < productLinesLists.length; idx++) {
        const line = productLinesLists[idx];
        if (productLinesLists[idx].id === basketLineId) {
            if (line.endCustomerAddress === null)
                line.endCustomerAddress = { id: endCustomerId, formatted: formated };
            if (line.endCustomerAddress !== undefined) {
                line.endCustomerAddress.formatted = formated;
                line.endCustomerAddress.id = endCustomerId;
            }
            productLinesLists[idx] = line;
        }
    }
    if(state.model === null){
        return state.model;
    }
    state.model.productLines.list = productLinesLists;
    return state.model;
}

function onBasketUpdate(state: State) {
    return {
        ...state,
        updating: true,
    };
}

function onBasketUpdated(state: State, action: BasketUpdatedAction) {
    const { updaterId, date } = action.payload;

    return {
        ...state,
        updating: false,
        updated: {
            ...state.updated,
            updaterId,
            date,
        },
    };
}

function onBasketChangeCompleted(state: State, action: BasketChangeCompletedAction) {
    const { updaterId, date } = 'updaterId' in action.payload ? action.payload : state.updated;
    const { linesAmount } = action.payload;

    const updatedBySync = updaterId === Updaters.Sync;
    const updatedByBasket = updaterId === Updaters.Basket;
    const hasUpdatedLines = linesAmount > 0;

    const summaryExpired = updatedBySync
        ? date !== state.modifiedDate
        : (
            hasUpdatedLines
            || updatedByBasket
            || updaterId === Updaters.SalesAgreement
            || (updaterId === Updaters.Checkout && !!state.summary && 'calculated' in state.summary && !!state.summary.calculated)
        );

    const modifiedDate = summaryExpired
        ? date || Date.now()
        : state.modifiedDate;

    const loading = hasUpdatedLines && !updatedByBasket;

    return {
        ...state,
        modifiedDate,
        modelExpired: updatedBySync,
        summary: {
            ...state.summary,
            expired: summaryExpired,
            loading,
            loaded: !loading,
        },
        updated: {
            updaterId,
            date,
            linesAmount,
        },
    };
}

function onBasketReceived(state: State, action: BasketReceivedAction) {
    const result = getNewStateFromReceivedBasket(state, action);
    const currentModifiedDate = state.modifiedDate;
    const newModifiedDate = result.modifiedDate;
    const isAvailable = 'isAvailable' in result.model ? result.model.isAvailable : undefined;
    if (newModifiedDate != null && currentModifiedDate !== newModifiedDate)
        result.summary = { ...result.summary, isAvailable, expired: true };
    else if (result.summary?.isAvailable !== isAvailable)
        result.summary = { ...result.summary!, isAvailable };

    delete result.syncBasket;
    delete result.modelExpired;

    return result;
}

function onBasketSummaryReceived(state: State, action: BasketSummaryReceivedAction) {
    if (!action.payload.basket)
        return { ...state, summary: null };

    const { basket, modifiedDate, salesAgreementInfo } = action.payload;
    const adjustedModifiedDate = adjustModifiedDate(modifiedDate);

    return {
        ...state,
        modifiedDate: adjustedModifiedDate || state.modifiedDate,
        summary: { ...basket, loaded: true, loading: false, expired: false },
        salesAgreementInfo: { ...salesAgreementInfo, loaded: true as const },
    };
}

function onBasketSummaryExpired(state: State) {
    return {
        ...state,
        summary: {
            ...state.summary,
            expired: true,
        },
        updated: {
            ...state.updated,
            linesAmount: 0,
        },
    };
}

function onBasketChangeStarted(state: State) {
    return {
        ...state,
        updated: {
            updaterId: null,
            date: null,
            linesAmount: 0,
        },
        summary: {
            ...state.summary!,
            loaded: false,
            loading: true,
        },
    };
}

function onBasketUpdating(state: State, action: AddProductsAction) {
    return {
        ...state,
        updatingBy: action.payload.updatedById,
        basketButtonClicked: action.payload.basketButtonClicked,
    };
}
/*171082 - 3.5.Two shopping carts(orders and quotes)*/
function onBasketHover(state: State, action: BasketHoverAction) {
    return {
        ...state,
        basketButtonClicked: action.payload.basketButtonClicked,
    };
}

function onNavigated(state: State) {
    if (state.modelExpired || state.syncBasket || state.updated.updaterId || (state.summary?.expired && state.summary.loading)) {
        const result = {
            ...state,
            updated: {
                updaterId: null,
                date: null,
                linesAmount: 0,
            },
        };
        delete result.modelExpired;
        delete result.syncBasket;
        return result;
    }
    return state;
}

function onBasketArrived(state: State, action: BasketArrivedAction) {
    const { basket, salesAgreementInfo, modifiedDate } = action.payload;
    return {
        ...state,
        syncBasket: { ...basket, salesAgreementInfo, modifiedDate },
    };
}

function onAgreementApplied(state: State, action: AgreementAppliedAction) {
    return {
        ...state,
        salesAgreementInfo: {
            ...state.salesAgreementInfo!,
            id: action.payload.salesAgreementId,
            isAppliedToLines: false,
        },
    };
}

function onAgreementCanceled(state: State) {
    return {
        ...state,
        salesAgreementInfo: {
            ...state.salesAgreementInfo!,
            id: undefined,
            isAppliedToLines: false,
        },
    };
}

function onAgreementLinesReceived(state: State, action: ReceiveAgreementLinesAction) {
    if (!state.model || !isBasketModel(state.model))
        return state;

    const { agreementLines, basketLineId } = action.payload;
    const productLinesList = state.model.productLines.list;
    if (agreementLines.length === 0 || !productLinesList?.length)
        return state;

    const { line, lineIndex, subLine, subLineIndex } = getTargetBasketLineInfo(productLinesList, basketLineId);
    if (line == null)
        return state;

    const availableSalesAgreementLines = subLine?.availableSalesAgreementLines || line.availableSalesAgreementLines;
    if (!availableSalesAgreementLines)
        return state;

    const uom = subLine?.uom || line.uom;
    const productUomId = uom?.id.toUpperCase();
    const salesAgreementLines = availableSalesAgreementLines.reduce<SalesAgreementLine[]>((acc, { id: availableAgreementLineId }) => {
        const availableAgreementLine = agreementLines.find(line => {
            return line.id === availableAgreementLineId && (line.uom ? line.uom.id.toUpperCase() === productUomId : true);
        });

        if (availableAgreementLine)
            acc.push(availableAgreementLine);

        return acc;
    }, []);

    if (salesAgreementLines.length === 0)
        return state;

    const newLine = subLine
        ? {
            ...line,
            subLines: [
                ...(line as ProductLine).subLines!.slice(0, subLineIndex!),
                { ...subLine, salesAgreementLines },
                ...(line as ProductLine).subLines!.slice(subLineIndex! + 1),
            ],
        }
        : { ...line, salesAgreementLines };

    return {
        ...state,
        model: {
            ...state.model,
            productLines: {
                ...state.model.productLines,
                list: [
                    ...productLinesList.slice(0, lineIndex!),
                    newLine,
                    ...productLinesList.slice(lineIndex! + 1),
                ],
            },
        },
    };
}
type BasketModelProductLinesList = BasketModel['productLines']['list'];
type ProductLine = Exclude<BasketModelProductLinesList[0], BasketConfigurationResultLine>;
//Ticket 171387: Cambridge Isotope 3.9. End customer
type EndCustomerBasketModelProductLinesList = EndCustomerBasketModel['productLines']['list'];

function getNewStateFromReceivedBasket(state: State, action: BasketReceivedAction) {
    const { basket, page, salesAgreementInfo } = action.payload;
    const newStateBase = {
        ...state,
        lastModifiedLineId: null,
        isQuickOrderMode: false,
        salesAgreementInfo: { ...salesAgreementInfo, loaded: true as const },
    };

    if (isReceivedBasket(basket)) {
        const { modifiedDate, ...model } = getBasketModelFromDetails(basket);

        return {
            ...newStateBase,
            model: { ...model, page },
            modifiedDate: adjustModifiedDate(modifiedDate) || newStateBase.modifiedDate,
        };
    } else if (isEmptyModel(basket)) {
        const { modifiedDate, ...model } = basket;

        return {
            ...newStateBase,
            model: { ...model, page },
            modifiedDate: adjustModifiedDate(modifiedDate) || newStateBase.modifiedDate,
        };
    }

    return {
        ...newStateBase,
        model: { ...basket, page },
    };
}

function getBasketModelFromDetails(basket: ReceivedBasket) {
    const {
        subTotal,
        totalPrice,
        totalPriceExcludingTax,
        prepayment,
        roundOff,
        productLines,
        ...model
    } = basket;

    return {
        ...model,
        totals: getTotalsSection(subTotal, totalPrice, totalPriceExcludingTax, prepayment, roundOff),
        productLines: {
            list: productLines.list.map(line => {
                const id = line.id || generateKey();
                if ('configuration' in line)
                    return { ...line, id };

                const subLines = line.subLines ? line.subLines.map(subLine => ({ ...subLine, id: subLine.id || generateKey() })) : line.subLines;
                return { ...line, id, subLines };
            }),
            totalCount: productLines.totalCount,
        },
    };
}

function getTotalsSection(subTotal: number | null, totalPrice: number | null, totalPriceExcludingTax: number | null, prepayment: number | null, roundOff: number) {
    if (subTotal == null && totalPrice == null && totalPriceExcludingTax == null && prepayment == null)
        return;

    return {
        sub: subTotal,
        price: totalPrice,
        priceExcludingTax: totalPriceExcludingTax,
        prepayment,
        roundOff,
    };
}

function adjustModifiedDate(modifiedDate: string | number | null | undefined) {
    return typeof modifiedDate === 'string' ? +new Date(modifiedDate) : modifiedDate;
}

function getTargetBasketLineInfo(productLinesList: BasketModelProductLinesList, basketLineId: string) {
    for (let idx = 0; idx < productLinesList.length; idx++) {
        const line = productLinesList[idx];
        if (line.id === basketLineId)
            return { line, lineIndex: idx };

        const subLines = 'subLines' in line && line.subLines;
        if (!subLines)
            continue;

        for (let subIdx = 0; subIdx < subLines.length; subIdx++) {
            const subLine = subLines[subIdx];
            if (subLine.id === basketLineId)
                return { line, lineIndex: idx, subLine, subLineIndex: subIdx };
        }
    }

    return {};
}
